.titulo-admin{
    padding: 4% 0% 3% 3%;
    font-weight: bolder;
}
.quitardiv{
    display: none;
}
.agregardiv{
    display: block;
}
.categoriaelegir{
    height: 150px;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: 1px solid #9b9b9b;
    padding-bottom: 5%;
}
.btn-inverse-success:not(.btn-inverse-light) {
    color: #17c964;
}
.btn-inverse-success {
    background-color: rgba(23, 201, 100, 0.2);
    background-image: none;
    border-color: rgba(23, 201, 100, 0);
}
.btn-inverse-success:hover {
    color: #ffffff;
    background-color: #17c964;
    border-color: #17c964;
}
.btn:focus,
.btn:active{
    outline: none;
    box-shadow: none;
}
.button-categoria{
    justify-content: end;
    display: flex;
    padding: 0%;
}
.tabla-admin{
    padding: 3% 0% 0% 5%;
}
.form__group {
    position: relative;
    width: 100%;
    padding: 7% 10% 2% 10%;
}
.form__img{
    position: relative;
    width: 30%;
}
.form__field {
    font-family: inherit;
    width: 100%;
    border: none;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 17px;
    color: black;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}
.form__field::placeholder {
    color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
    font-size: 17px;
    cursor: text;
    top: 20px;
}
.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 17px;
    color: #9b9b9b;
    pointer-events: none;
}
.form__field:focus {
    padding-left: 6px;
    font-weight: 400;
    border-image: linear-gradient(to right, #ed571d, #ed571d);
    border-image-slice: 1;
}
.form__field:focus ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 17px;
    color: #ed571d;
    font-weight: 700;
}
.form__field:required, .form__field:invalid {
    box-shadow: none;
}
.edit-admin{
    cursor: pointer;
}
.form-select:focus,
.form-select:active{
    outline: none;
    box-shadow: none;
}

.image-input {
    text-align: center;
}
.image-input input {
    display: none;
}
.image-input label {
    display: block;
    color: #FFF;
    background: #ed571d;
    padding: 0.3rem 0.6rem;
    font-size: 115%;
    cursor: pointer;
}
.image-input label i {
    font-size: 125%;
    margin-right: 0.3rem;
}
.image-input label:hover i {
    animation: shake 0.35s;
}
.image-input img {
    max-width: 200px;
    display: none;
}
.image-input span {
    display: none;
    text-align: center;
}
.change-image{
    cursor: pointer;
    display: block;
    background-color: red;
    padding: 1%;
    color: white;
    margin-top: 2%;
}
.change-imagen{
    cursor: pointer;
    display: block;
    background-color: red;
    padding: 1%;
    color: white;
    margin-top: 2%;
}
.change-imagens{
    cursor: pointer;
    display: block;
    background-color: red;
    padding: 1%;
    color: white;
    margin-top: 2%;
}
.change-image-two{
    cursor: pointer;
    display: block;
    background-color: red;
    padding: 1%;
    color: white;
    margin-top: 2%;
}
.change-image-edit-galeria{
    cursor: pointer;
    display: block;
    background-color: red;
    padding: 1%;
    color: white;
    margin-top: 2%;
}
.change-image-prigaleri{
    cursor: pointer;
    display: block;
    background-color: red;
    padding: 1%;
    color: white;
    margin-top: 2%;
}
.change-imagen-twosec{
    cursor: pointer;
    display: block;
    background-color: red;
    padding: 1%;
    color: white;
    margin-top: 2%;
}
.change-image-primg{
    cursor: pointer;
    display: block;
    background-color: red;
    padding: 1%;
    color: white;
    margin-top: 2%;
}
.image-preview-galeria-edit{
    margin: auto;
}
.image-preview-twoedit{
    margin: auto;
}
.image-previewedit{
    margin: auto;
}
.image-preview-primg{
    margin: auto;
}
.image-previewtwo{
    margin: auto;
}
.image-preview-galeria-pri{
    margin: auto;
}
@keyframes shake {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(10deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
.nav-tabs .nav-link{
    color: black;
    /* margin-left: 1%; */
}
.nav-tabs .nav-link.active{
    background-color: #ed571d;
    border-color: #ed571d;
}
/*Checkboxes styles*/
input[type="checkbox"] { 
    display: none; 
}
input[type="checkbox"] + label {
    display: block;
    position: relative;
    padding-left: 27px;
    font-size: 17px;
    color: #9b9b9b;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
input[type="checkbox"] + label:last-child { 
    margin-bottom: 0; 
}
input[type="checkbox"] + label:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid #ed571d;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: all .12s, border-color .08s;
    transition: all .12s, border-color .08s;
}
input[type="checkbox"]:checked + label:before {
    width: 10px;
    top: -5px;
    left: 5px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}