:root{
    --color-background: #f6f6f9;
    --color-white: #fff;
    --color-dark: #363949;
    --color-dark-variant: #677483;
    --color-light: rgba(132, 139, 200, 0.18);
    --color-black: #000000;
}
.dark-theme-variables{
    --color-background: #181a1e;
    --color-white: #202528;
    --color-dark: #edeffd;
    --color-dark-variant: #a3bdcc;
    --color-light: rgba(0, 0, 0, 0.4);
    --color-black: #fff;
}
.containeres{
    display: grid;
    width: 99%;
    margin: 0 auto;
    grid-template-columns: 14rem auto 18rem;
}
a{
    color: black;
}
h1{
    font-weight: 800;
    font-size: 1.8rem;
}
.texto-admin{
    color: var(--color-black);
}
h2{
    font-size: 1.4rem;
}
h3{
    font-size: 0.87rem;
    margin-bottom: 0px;
}
h4{
    font-size: 0.8rem;
}
small{
    font-size: 0.75rem;
}
.profile-photo{
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    overflow: hidden;
}
.text-muted{
    color: #7d8da1;
}
b{
    color: black;
}
.primary{
    color: #7380ec;
}
.danger{
    color: #ff7782;
}
.success{
    color: #41f1b6;
}
.warning{
    color: #ffbb55;
}
aside{
    height: 100vh;
}
aside .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.4rem;
}
aside .logo{
    display: flex;
    gap: 0.8rem;
}
aside .logo img{
    width: 2rem;
    height: 2rem;
}
aside .close{
    display: none;
}
/*========================= SIDEBAR ================================*/
aside .sidebar{
    display: flex;
    flex-direction: column;
    height: 86vh;
    position: relative;
    top: 3rem;
}
aside h3{
    font-weight: 500;
}
aside .sidebar a{
    display: flex;
    color: #7d8da1;
    margin-left: 2rem;
    gap: 1rem;
    align-items: center;
    position: relative;
    height: 3.7rem;
    text-decoration: none;
    transition: all 300ms ease;
}
aside .sidebar a .icon-dashboard{
    font-size: 1.6rem;
    transition: all 300ms ease;
}
aside .sidebar a:last-child{
    position: absolute;
    bottom: 2rem;
    width: 100%;
}
aside .sidebar a.active{
    background: var(--color-light);
    color: #7380ec;
    margin-left: 0;
}
aside .sidebar a.active:before{
    content: "";
    width: 6px;
    height: 100%;
    background: #7380ec;
}
aside .sidebar a.active i{
    color: #7380ec;
    margin-left: calc(1rem - 3px);
}
aside .sidebar a:hover{
    color: #7380ec;
}
aside .sidebar a:hover .icon-dashboard{
    margin-left: 1rem;
}
aside .sidebar .message-count{
    background: #ff7782;
    color: var(--color-white);
    padding: 2px 10px;
    font-size: 11px;
    border-radius: 0.4rem;
}
main .date{
    display: inline-block;
    background: var(--color-light);
    border-radius: 0.4rem;
    margin-top: 1rem;
    padding: 0.5rem 1.6rem;
}
main .date input[type="date"]{
    background: transparent;
    color: var(--color-black);
}
.right:hover{
    transform: inherit;
}
.right .top{
    display: flex;
    justify-content: end;
    gap: 2rem;
}
.right .top button{
    display: none;
}
.right .theme-toggler{
    background: var(--color-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.6rem;
    width: 4.2rem;
    cursor: pointer;
    border-radius: 0.4rem;
}
.right .theme-toggler i{
    font-size: 1.2rem;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.right .theme-toggler i.active{
    background: #7380ec;
    color: white;
    border-radius: 0.4rem;
}
.right .top .profile{
    display: flex;
    gap: 2rem;
    text-align: right;
}
.ocultar{
    display: none;
}
.ver{
    display: block;
}
@media screen and (max-width: 1200px){
    .containeres{
        width: 94%;
        grid-template-columns: 7rem auto 23rem;
    }
    aside .logo h2{
        display: none;
    }
    aside .sidebar h3{
        display: none;
    }
    aside .sidebar a{
        width: 5.6rem;
    }
    aside .sidebar a:last-child{
        position: relative;
        margin-top: 1.8rem;
    }
}

@media screen and (max-width: 768px){
    .containeres{
        width: 100%;
        grid-template-columns: 1fr;
    }
    aside{
        position: fixed;
        left: -100%;
        background: var(--color-white);
        width: 18rem;
        z-index: 3;
        box-shadow: 1rem 3rem 4rem var(--color-light);
        height: 100vh;
        padding-right: 1.8rem;
        display: none;
        animation: showMenu 400ms ease forwards;
    }
    @keyframes showMenu {
        to {
            left: 0;
        }
    }
    aside .logo{
        margin-left: 1rem;
    }
    aside .logo h2{
        display: inline;
    }
    aside .sidebar h3{
        display: block;
    }
    aside .sidebar a{
        width: 100%;
        height: 3.4rem;
    }
    aside .sidebar a:last-child{
        position: absolute;
        bottom: 5rem;
    }
    aside .close{
        display: inline-block;
        cursor: pointer;
    }
    main{
        margin-top: 8rem;
        padding: 0 1rem;
    }
    /* .right{
        width: 94%;
        margin: 0 auto 4rem;
    } */
    .right .top{
        position: fixed;
        top: 0;
        left: 0;
        align-items: center;
        padding: 0 0.8rem;
        height: 4.6rem;
        background: var(--color-white);
        width: 100%;
        margin: 0;
        z-index: 2;
        box-shadow: 0 1rem 1rem var(--color-light);
    }
    .right .top .theme-toggler{
        width: 4.4rem;
        position: absolute;
        left: 66%;
    }
    .right .profile .info{
        display: none;
    }
    .right .top button{
        display: inline-block;
        background: transparent;
        cursor: pointer;
        color:  var(--color-dark);
        position: absolute;
        left: 1rem;
    }
    .right .top button i{
        font-size: 2rem;
    }
}