@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
  
@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.contenedor-admin{
  display: flex;
}
.espacio-admin{
  width: 100%;
}
.espacio-basico{
  width: 100%;
  padding-left: 2%;
}
.img-minilogo{
  width: 20%;
}
.mini-titulo{
  padding-left: 3%;
  width: 100%;
  margin: 0px;
}
body {
  font-size: 0.9rem;
}
.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
  
/*----------------page-wrapper----------------*/
.page-wrapper {
  height: 100vh;
}
.page-wrapper .theme {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 4px;
  margin: 2px;
}
.page-wrapper .theme.chiller-theme {
  background: #1e2229;
}
  
/*----------------toggeled sidebar----------------*/
.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}

@media screen and (min-width: 768px) {
  .page-wrapper.toggled .page-content {
    padding-left: 300px;
  }
}

/*----------------show sidebar button----------------*/
#show-sidebar {
  position: fixed;
  left: 0;
  top: 10px;
  border-radius: 0 4px 4px 0px;
  width: 35px;
  transition-delay: 0.3s;
}
.page-wrapper.toggled #show-sidebar {
  left: -40px;
}

/*----------------sidebar-wrapper----------------*/
.sidebar-wrapper {
  width: 260px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 999;
}
.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.sidebar-wrapper a {
  text-decoration: none;
}
  
/*----------------sidebar-content----------------*/
.sidebar-content {
  max-height: calc(100% - 30px);
  height: calc(100% - 30px);
  overflow-y: auto;
  position: relative;
}
.sidebar-content.desktop {
  overflow-y: hidden;
}
  
/*--------------------sidebar-brand----------------------*/
.sidebar-wrapper .sidebar-brand {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}
.sidebar-wrapper .sidebar-brand > a {
  text-transform: uppercase;
  font-weight: bold;
  flex-grow: 1;
}
.sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 20px;
}

/*--------------------sidebar-header----------------------*/
.sidebar-wrapper .sidebar-header {
  padding: 20px;
  overflow: hidden;
}
.sidebar-wrapper .sidebar-header .user-pic {
  float: left;
  width: 60px;
  padding: 2px;
  border-radius: 12px;
  margin-right: 15px;
  overflow: hidden;
}
.sidebar-wrapper .sidebar-header .user-pic img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.sidebar-wrapper .sidebar-header .user-info {
  float: left;
}
.sidebar-wrapper .sidebar-header .user-info > span {
  display: block;
}
.sidebar-wrapper .sidebar-header .user-info .user-role {
  font-size: 12px;
}
.sidebar-wrapper .sidebar-header .user-info .user-status {
  font-size: 11px;
  margin-top: 4px;
}
.sidebar-wrapper .sidebar-header .user-info .user-status i {
  font-size: 8px;
  margin-right: 4px;
  color: #5cb85c;
}
  
/*-----------------------sidebar-search------------------------*/
.sidebar-wrapper .sidebar-search > div {
  padding: 10px 20px;
}
  
/*----------------------sidebar-menu-------------------------*/
.sidebar-wrapper .sidebar-menu {
  padding-bottom: 10px;
}
.sidebar-wrapper .sidebar-menu .header-menu span {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  display: inline-block;
}
.sidebar-wrapper .sidebar-menu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 8px 30px 8px 20px;
}
.sidebar-wrapper .sidebar-menu ul li a i {
  margin-right: 10px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}
.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  font-style: normal;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 14px;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  padding: 5px 0;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  padding-left: 25px;
  font-size: 13px;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
  content: "\f111";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  font-size: 8px;
}
.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
  float: right;
  margin-top: 0px;
}
.sidebar-wrapper .sidebar-menu .sidebar-submenu {
  display: none;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
  transform: rotate(90deg);
  right: 17px;
}
  
/*--------------------------side-footer------------------------------*/
.sidebar-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
}
.sidebar-footer > a {
  flex-grow: 1;
  text-align: center;
  height: 30px;
  line-height: 30px;
  position: relative;
}
.sidebar-footer > a .notification {
  position: absolute;
  top: 0;
}
.badge-sonar {
  display: inline-block;
  background: #980303;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  position: absolute;
  top: 0;
}
.badge-sonar:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #980303;
  opacity: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: sonar 1.5s infinite;
}
  
/*--------------------------page-content-----------------------------*/
.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  padding-left: 0px;
  padding-top: 20px;
}
.page-wrapper .page-content > div {
  padding: 20px 40px;
}
.page-wrapper .page-content {
  overflow-x: hidden;
}
  
/*------scroll bar---------------------*/
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #0e2f7f;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #0e2f7f;
}
::-webkit-scrollbar-thumb:active {
  background: #0e2f7f;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
  
/*-----------------------------chiller-theme-------------------------------------------------*/
.chiller-theme .sidebar-wrapper {
  background: #f5f5f9;
}
/* .chiller-theme .sidebar-wrapper .sidebar-header,
.chiller-theme .sidebar-wrapper .sidebar-search,
.chiller-theme .sidebar-wrapper .sidebar-menu {
  border-top: 1px solid #3a3f48;
} */
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
  border-color: transparent;
  box-shadow: none;
}
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-brand > a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.chiller-theme .sidebar-footer > a {
  color: #818896;
}
.chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover > a,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.chiller-theme .sidebar-wrapper .sidebar-brand > a:hover,
.chiller-theme .sidebar-footer > a:hover i {
  color: #b8bfce;
}
.page-wrapper.chiller-theme.toggled #close-sidebar {
  color: black;
}
.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
  color: #b8bfce;
}
.chiller-theme .sidebar-wrapper ul li:hover a i,
.chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus + span,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
  color: #ed571d;
}
.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
  color: #000;
}
.chiller-theme .sidebar-footer > a:first-child {
  border-left: none;
}
.chiller-theme .sidebar-footer > a:last-child {
  border-right: none;
}
.offcanvas-body{
  overflow-y: inherit;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.menu {
  width: 100%;
  text-transform: capitalize;
}
.menu a {
  text-decoration: none;
  color: black;
  padding: 9px 20px;
  display: block;
  font: 400 11px/12px Arial;
  transition: all 0.15s linear;
  font-size: 14px;
}
.menu > li {
  position: relative;
}
.menu a:hover {
  background: #001267;
  color: #fff !important;
  border-radius: 5px;
}
.submenu:after{
  content: ' ';
  display: block;
  border: 5px solid transparent;
  position: absolute;
  top: 2px;
  left: -15px;
  border-left-color: #ed571d !important;
  color: #ed571d !important;
  margin-top: -1%;
}
.submenu {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  text-transform: none;
  background: white;
}
.submenu li {
  max-height:0;
  transition: all 0.25s linear;
  overflow: hidden;
  transform: scale(0);
}
.submenu:after {
  content: ' ';
  display: block;
  border: 5px solid transparent;
  border-left-color: black;
  color: black;
  position: absolute;
  top: 2px;
  left: -15px;
} 
li:hover > .submenu > li {
  max-height: 44px;
  transform: scale(1);
}
.img-cont {
  height: 20rem;
  border-radius: 5px;
  overflow: hidden;
}
.img-principal{
  height: 30rem;
}
.img-galeria-product{
  height: 60px;
}
.card--skeleton .img-cont {
  background: #eee;
}
.card--skeleton::before {
  content: '';
  position: absolute;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.9),
    transparent
  );
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  animation: loading 0.6s infinite;
}
@keyframes loading {
  0% {
    transform: skewX(-10deg) translateX(-100%);
  }
  100% {
    transform: skewX(-10deg) translateX(200%);
  }
}
.productos-index .panel{
  position: absolute;
  top: 1%;
  left: -1000000000%;
  width: 18%;
  display: flex;
  align-items: center;
  flex-flow: column;
}
.contenedor_icon:hover .panel{
  left: 4%;
  opacity: 1;
}
.contenedor_icon:hover .fondo_precios{
  background: #0e2f7f42;
}
.fondo_precios{
  padding: 3% 0%;
}
.productos-index .panel .icon_product{
  margin: 12% 0;
  color: #ed571d;
}
.offcanvas-header{
  border-bottom: 2px solid #f7f7f7;
}
.offcanvas-title{
  font-size: 26px;
  margin-left: 10%;
  color: #ed571d;
}
/*tooltip Box*/
.con-tooltip {
  position: relative;
  border-radius: 9px;
  padding: 0 15%;
  margin: 6px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.tooltip {
  visibility: hidden;
  z-index: 1;
  opacity: .40;
  background: #ed571d;
  color: #fff;
  position: absolute;
  top:-140%;
  left: -25%;
  width: 250%;
  padding: 3%;
  top: 41%;
  font-size: 12px;
  border-radius: 9px;
  transform: translateY(9px);
  transition: all 0.3s ease-in-out;
}
.con-tooltip:hover .tooltip{
  visibility: visible;
  transform: translateY(-10px);
  opacity: 1;
  transition: .3s linear;
}
.right:hover{
  transform: translateX(6px);
}
.right .tooltip{ 
  left:90%;
}
.right .tooltip::after{
  top:30%;
  left:-8%;
  transform: rotate(90deg);
}

/* BUTTONS */
.button-delete{
  margin-right: 4%;
  background: #ed571d;
  border-radius: 50%;
  border: transparent;
}
.button-plus{
  margin-left: 4%;
  background: #ed571d;
  border-radius: 50%;
  border: transparent;
}

.small-img-row{
  display: block;
  justify-content: space-between;
  width: 12%;
}
.small-img-col{
  flex-basis: 24%;
  cursor: pointer;
  display: inline-flex;
}

.color-galeria{
  border-right: 3px solid #ed571d;
}
@media screen and (max-width: 1532px){
  .contenedor_icon:hover .panel {
    left: 8%;
  }
}
@media screen and (max-width: 1100px){
  .contenedor_icon:hover .panel {
    left: 11%;
  }
}
@media screen and (max-width: 769px){
  .img-principal{
    height: 17rem;
  }
  .img-galeria-product{
    height: 30px;
  }
}