.nosotros{
    padding: 87px 100px 80px 100px;
}
.title-nosotros{
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 20px;
}
.text-content{
    margin-bottom: 1rem;
}
.btn-subir{
    display: block;
    padding: 10px 21px;
    border: 1px solid #ed571d;
    color: white;
    background: #ed571d;
    border-radius: 3px;
    cursor: pointer;
    padding: 16px 43px !important;
}
.btn-subir:hover{
    background-color: white;
    border: 1px solid #ed571d;
    color: #ed571d;
    transition: all 0.5s ease-out;
}
.postulaciones_env{
    padding: 10px 20px !important;
}
.nosotros-contacto{
    display: flex;
    justify-content: center;
}

/************************************************* PREGUNTAS FRECUENTES *********************************************/
.preguntasfree{
    text-align: center;
    padding: 3% 0% 1%;
    color: #ed571d;
    font-weight: bold;
    font-size: 30px;
}
.menupreguntas{
    margin: 0 auto;
    padding: 1% 2% 3%;
    width: 100%;
    font-family: sans-serif;
    color:#4d5974;
    font-size: 18px;
}
.title-cuestion {
    display: block;
    margin: 0 0 4px 0;
    padding : 15px 15px 15px 0;
    line-height: 1;
    cursor :pointer;
    border-bottom: 1px solid #e5e5e5;
}
.question{
    position: relative;
}
.accordion[type="checkbox"] + label:before{
    display: none;
}
.menupreguntas ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.menupreguntas li {
    height: 0;
    overflow: hidden;
    transition: all 0.5s;
    font-size: 16px;
}
#icon span {
    position: absolute;
    top: 11px;
    right: 11px;
    display: inline-block;
    width: 22px;
    height: 22px;
    border: 1px solid #4d5974;
    border-radius: 22px;
}
#icon span::before{
    display: block;
    position: absolute;
    content: '';
    top: 10px;
    left: 5px;
    width: 10px;
    height: 2px;
    background: #4d5974;
}
#icon span::after{
    display: block;
    position: absolute;
    content: '';
    top: 6px;
    left: 9px;
    width: 2px;
    height: 10px;
    background: #4d5974;
}
#type1:checked ~ #links1 li,
#type2:checked ~ #links2 li,
#type3:checked ~ #links3 li,
#type4:checked ~ #links4 li,
#type5:checked ~ #links5 li,
#type6:checked ~ #links6 li,
#type7:checked ~ #links7 li,
#type8:checked ~ #links8 li,
#type9:checked ~ #links9 li,
#type10:checked ~ #links10 li,
#type11:checked ~ #links11 li{
    height: auto;
    opacity: 1;
    padding: 1.5% 2%;
}
#type1:checked ~ .title-cuestion #icon span::after,
#type2:checked ~ .title-cuestion #icon span::after,
#type3:checked ~ .title-cuestion #icon span::after,
#type4:checked ~ .title-cuestion #icon span::after,
#type5:checked ~ .title-cuestion #icon span::after,
#type6:checked ~ .title-cuestion #icon span::after,
#type7:checked ~ .title-cuestion #icon span::after,
#type8:checked ~ .title-cuestion #icon span::after,
#type9:checked ~ .title-cuestion #icon span::after,
#type10:checked ~ .title-cuestion #icon span::after,
#type11:checked ~ .title-cuestion #icon span::after{
    width: 0;
}
#type1:checked ~ .title-cuestion,
#type2:checked ~ .title-cuestion,
#type3:checked ~ .title-cuestion,
#type4:checked ~ .title-cuestion,
#type5:checked ~ .title-cuestion,
#type6:checked ~ .title-cuestion,
#type7:checked ~ .title-cuestion,
#type8:checked ~ .title-cuestion,
#type9:checked ~ .title-cuestion,
#type10:checked ~ .title-cuestion,
#type11:checked ~ .title-cuestion{
    color: #ed571d;
    border-bottom: 1px solid #ed571d;
}
#type1:checked ~ .title-cuestion #icon span::before,
#type2:checked ~ .title-cuestion #icon span::before,
#type3:checked ~ .title-cuestion #icon span::before,
#type4:checked ~ .title-cuestion #icon span::before,
#type5:checked ~ .title-cuestion #icon span::before,
#type6:checked ~ .title-cuestion #icon span::before,
#type7:checked ~ .title-cuestion #icon span::before,
#type8:checked ~ .title-cuestion #icon span::before,
#type9:checked ~ .title-cuestion #icon span::before,
#type10:checked ~ .title-cuestion #icon span::before,
#type11:checked ~ .title-cuestion #icon span::before{
    background: #ed571d;
}
#type1:checked ~ .title-cuestion #icon span,
#type2:checked ~ .title-cuestion #icon span,
#type3:checked ~ .title-cuestion #icon span,
#type4:checked ~ .title-cuestion #icon span,
#type5:checked ~ .title-cuestion #icon span,
#type6:checked ~ .title-cuestion #icon span,
#type7:checked ~ .title-cuestion #icon span,
#type8:checked ~ .title-cuestion #icon span,
#type9:checked ~ .title-cuestion #icon span,
#type10:checked ~ .title-cuestion #icon span,
#type11:checked ~ .title-cuestion #icon span{
    border: 1px solid #ed571d;
}

/************************************************* POLITICAS DE PRIVACIDAD ***************************************************************/
.titulo-terminos{
    text-align: center;
    padding: 3% 0% 1%;
    color: #ed571d;
    font-weight: bold;
    font-size: 30px;
}
.titulo-trabajo{
    text-align: center;
    padding: 3% 0% 0%;
    color: #ed571d;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 0px;
}
.concepto-termino{
    text-align: justify;
    margin-bottom: 3%;
}
.urls{
    overflow-wrap: anywhere;
    color: #ed571d;
    padding-left: 0.5%;
    padding-right: 0.5%;
}

/************************************************* POLITICAS DE DEVOLUCIONES ***************************************************************/
.title-devoluciones{
    text-align: center;
    padding: 3% 0% 1%;
    color: #ed571d;
    font-weight: bold;
    font-size: 30px;
}
.politicas-cambios-devoluciones{
    text-align: justify;
    margin-bottom: 2%;
    margin-top: 2%;
}
.concepto-terminos{
    text-align: justify;
    margin-bottom: 1%;
}
.concepto-terminos{
    text-align: justify;
    margin-bottom: 1%;
}
.politicas-cambios {
    padding: 1% 2%;
    text-align: justify;
}
.bottom {
    margin-bottom: 5%;
}

/************************************************* TERMINOS Y CONDICIONES ***************************************************************/
.termino{
    text-align: justify;
    margin-bottom: 1rem;
}