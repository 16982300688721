@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
    font-family: Imported;
    src: url(/static/media/dinpro-medium.48a3635c.otf);
}
@font-face {
    font-family: BoldDinPro;
    src: url(/static/media/dinpro-bold.05078759.otf);
}
@font-face {
    font-family: Ligth;
    src: url(/static/media/dinpro-light.e0be9bd5.otf);
}
@font-face {
    font-family: RegularDinPro;
    src: url(/static/media/DINPro-Regular.86fda987.otf);
}
@font-face {
    font-family: AvertaBold;
    src: url(/static/media/Averta-Bold.fe2229d6.otf);
}
@font-face {
    font-family: AvertaRegular;
    src: url(/static/media/Averta-Regular.03079f48.otf);
}
.dinpro{
    font-family: Imported;
    font-weight: 700;
}
.dinproBold{
    font-family: BoldDinPro;
    font-weight: 800;
}
.dinproLigth{
    font-family: Ligth;
    font-weight: 300;
}
.dinproRegular{
    font-family: RegularDinPro;
    font-weight: 400;
}
.avertaBold{
    font-family: sans-serif;
    font-weight: 600;
}
.avertaRegular{
    font-family: sans-serif;
    font-weight: 400;
}
/* BOLD */
.poppins-bold{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}
.poppins-regular{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.poppins-medium{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.poppins-light{
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color: #ed571d;
}
.nav-pills .nav-link{
    color: #0e2f7f;
}
.ir-arriba {
    display:none;
    padding: 20px;
    background-repeat: no-repeat;
    font-size:30px;
    color:#ed571d;
    cursor:pointer;
    position: fixed;
    bottom:20px;
    right:20px;
    z-index: 2;
}
.maps_corporativo_trujillo{
    margin: 0px;
    padding-right: 4%;
}
.maps_corporativo_lima{
    margin: 0px;
    padding-left: 4%;
}
.maps_corporativos{
    display: flex;
    margin-bottom: 2%;
}
.texto-tira-beneficios{
    margin-top: 5%;
    color: #001267;
    font-size: 15px;
    margin-bottom: 2%;
    line-height: 1;
}
.contorno_logo_corporativo{
    background: white;
    border-radius: 5px;
    justify-content: center;
    display: flex;
    align-items: center;
}
.menu_corporativo{
    display: flex;
    padding: 0px;
    margin: 0 auto;
}
.menu_corporativo_movil{
    display: none;
}
.logo_corporativo{
    width: 75%;
}
.contenedor_beneficios{
    width: 100%;
    background: #ececec;
    margin: 2% 0% 3%;
    border-radius: 20px;
}
.contorno_contacto{
    width: 100%;
    margin-top: 2%;
    display: flex;
    justify-content: center;
}
.contacto_bloque{
    padding-bottom: 6%;
    margin-top: 1%;
}
.costado{
    padding-left: 7%;
}
.boton_de_enviar{
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    color: white;
    background: #ed571d;
    border: 1px solid #ed571d;
    border-radius: 15px; 
}
.button_contacto{
    width: 65%;
    padding-top: 2%;
}
.input_contacto{
    width: 65%;
    padding-top: 5%;
}
.telefono_contacto{
    width: 80%;
    margin-right: -19%;
}
.contenedor_contacto{
    background: #f6f6f6;
    width: 100%;
    height: 100%;
    border-top-right-radius: 5%;
    border-bottom-right-radius: 5%;
    padding-top: 5%;
}
.contexto_info{
    margin-bottom: 0px;
    display: flex;
    margin-left: 10%;
    font-size: 16px;
    font-weight: 500;
}
.punto_info{
    width: 35px;
    height: 35px;
}
.titulo_corporativo{
    font-size: 18px;
    font-weight: 700;
    color: white; 
}
.slider_corporativo{
    display: flex;
    padding-bottom: 4%;
}
.cajadetexto_corporativo{
    font-size: 17px;
    width: 70%;
    margin: auto;
    padding-left: 6%;
}
.navbar_corporativo{
    color: white;
    text-decoration: none;
}
.navbar_corporativo:hover{
    color: white;
}
.formulario_corporativo{
    background: transparent;
    color: #0e2f7f;
    border: 2px solid #888888;
    border-radius: 10px;
    padding: 2% 8%;
    font-weight: 600;
}
#exampleFormControlInput1::placeholder{
    color: #888888;
}
.formulario_corporativo:focus{
    background: transparent;
    color: #0e2f7f;
    border: 2px solid #0e2f7f;
}
#exampleFormControlInput1:focus::placeholder{
    color: transparent;
}
.ir-arriba:hover{
    color: #0e2f7f;
    transition: 0.5s;
}
#exampleModalote{
    background: rgba(8,14,89,0.64) !important;
    z-index: 1111;
}
.navbar_corporativo_flotante{
    background: #ed571d;
    height: 65px;
    margin-top: -2%;
    position: absolute;
    border-radius: 5px;
    display: flex;
    padding: 0px;
}
.fondo-corporativo{
    -webkit-background-size: 100%;
    max-width: 100%;
    max-height: 100%;
    height: 430px;
}
#linea_corporativa{
    height: 20px;
    width: 1.5%;
    border-width: 0;
    color: #001267;
    margin: auto 3%;
    opacity: 1;
    border-radius: 15px;
}
/* COTIZACIONES */
.ButtonCoti{
    bottom: 9%;
    position: fixed;
    z-index: 2;
    background: transparent;
    border: transparent;
    width: 12%;
    padding: 0px; 
}
.espacio_productos_promo{
    padding: 3% 3% 1%;
}
.margen {
    border-radius: 5%;
}
.margen:hover {
    border: 3px solid #fe6313;
    cursor: pointer;
    transition: 0.1s;
}
.margen.seleccionado {
    border: 3px solid #fe6313;
    transition: 0.1s;
}
.margenlaptop, .margenpc{
    height: 150px;
}
.laptopopc{
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    padding: 3% 0%;
}
.tiradeimagenes{
    border-left: thick solid #ed571d;
    height: 17%;
    left: 50%;
    position: absolute;
    margin-top: 1.5%;
}
.tipouso{
    font-size: 25px;
    font-weight: bold;
    color: #ed571d;
    margin: 1% 10% 0%; 
}
.estilocoti{
    font-size: 25px;
    font-weight: bold;
    color: #ed571d;
    margin: 1% 6% 0%;
}
.tiprocesador{
    font-size: 25px;
    font-weight: bold;
    color: #ed571d;
    margin: 1% 10% 0%;
}
.espaciobajo{
    display: flex;
    justify-content: space-evenly;
    margin: 3% 0%;
}
.ramygraficos{
    display: flex;
    justify-content: space-evenly;
    margin: 3% 0%;
    margin-bottom: 5%;
}
.linederam{
    border-left: thick solid #ed571d;
    height: 70%;
    position: absolute;
    margin-top: 1.5%;
}
.lineaestilo{
    border-left: thick solid #ed571d;
    height: 70%;
    left: 50%;
    position: absolute;
    margin-top: 1.5%;
}
.cotizaciones{
    font-size: 20px;
    font-weight: bold;
    background: #eaeaea;
    border: 1px solid #eaeaea;
    color: #004ba2;
    margin-bottom: 3%;
    border-radius: 15px;
}
.borderdeprocesador{
    border: 3px solid #004ba2;
}
.fondosubprocesadores{
    font-size: 25px;
    font-weight: bold;
    background: #ed571d;
    border: 1px solid #ed571d;
    color: #ffffff;
    margin-bottom: 3%;
    transition: 1s ease;
    border-radius: 15px;
}
.fondorprocesador{
    padding: 0%;
    background: white;
}
.fondoprocesadores{
    padding: 1%;
    background: #eaeaea;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    transition: 0.5s ease;
}
.seleccionadobotones{
    font-size: 20px;
    font-weight: bold;
    background: #ed571d;
    border: 1px solid #ed571d;
    color: #ffffff;
    margin-bottom: 3%;
    transition: 0.7s ease;
    border-radius: 15px;
}
.cotizaciones:hover{
    font-size: 20px;
    font-weight: bold;
    background: #ed571d;
    border: 1px solid #ed571d;
    color: #ffffff;
    margin-bottom: 3%;
    transition: 0.7s ease;
    border-radius: 15px;
}
.prueba{
    display: flex;
    justify-content: space-evenly;
    margin: 3% 0%;
    margin-bottom: 5%;
}
.intelvisible{
    background: #eaeaea;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    border-radius: 15px;
    padding: 1% 0%;
    margin: 0px;
}
.intelvisible2{
    background: #eaeaea;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    border-radius: 15px;
    padding: 1% 0%;
    margin: 0px; 
}
.oculto{
    display: none;
}
.activo{
    display: block;
}
/************************************************ CONTACTO ***********************************************************/
.contacto {
    width: 30%;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
}
.contacto img{
    width: 30%;
}
.title-contac {
    font-size: 24px;
    font-family: "Roboto", Sans-serif;
    font-weight: bold;
    text-align: center;
}
.title-contact-form {
    font-family: "Roboto", Sans-serif;
    font-size: 15px;
    line-height: 1.4em;
    padding: 0px 60px 0px 60px;
    text-align: center;
    margin-bottom: 3%;
}
.title-contacto {
    font-size: 2.1rem;
}
.label-coti {
    font-family: "Roboto", Sans-serif;
    margin-bottom: 0px;
}
.input-bottom {
    margin-bottom: 2%;
}
.input-contac {
    border: 1px solid #818a91;
}
.input-contac::placeholder {
    color: #c0bdbd;
}
/************************************ FIN CONTACTO **********************************************/
/*************************************** PERFIL *************************************************/
.foto-cuenta {
    display: contents;
}
.img-cuenta {
    width: 15%;
}
.margen_circle {
    border-radius: 50%;
}
.margen_circle:hover {
    border: 3px solid #fe6313;
    cursor: pointer;
    transition: 0.1s;
}
.margen_circle.seleccionado {
    border: 3px solid #fe6313;
    transition: 0.1s;
}
.lapiz_edit {
    position: absolute;
    bottom: 10%;
    float: right;
    margin: 0px 10%;
}
.lapiz_edit .btn_button {
    background: white;
    border-radius: 50px;
    border: 2px solid #fe6313;
    width: 35px;
    height: 35px;
}
.lapiz_edit .btn_button i {
    font-size: 17px;
    color: #003a7e;
}
.lapiz_edit .btn_button:hover {
    background: #fe6313;
    border-radius: 50px;
    border: 2px solid #fe6313;
    transition: 1s;
    width: 35px;
    height: 35px;
}
.lapiz_edit .btn_button:hover i {
    font-size: 17px;
    color: white;
    transition: 1s;
}
.info-user-cuenta {
    margin: auto 0px;
}
/*************************************** FIN DEL PERFIL *******************************************/
.title-corporativo {
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    padding-bottom: 5%;
}
.especificaciones-corporativo{
    font-size: 16px; 
    margin-bottom: 2%;
}
.fondo-cotizaciones {
    padding: 50px 100px 60px;
}
.caja-corporativo {
    margin-top: 3%;
}
.imagen-corporativo{
    border-radius: 30px;
}
.vermas_corporativo {
    position: absolute;
    width: 100%;
    height: 80%;
    text-align-last: center;
    visibility: hidden;
    padding-right: 30px;
}
.caja-corporativo:hover .vermas_corporativo {
    visibility: visible;
    z-index: 999;
}
.pantalla-movil{
    display: none;
}
.vermas_corporativo button {
    margin-top: 30%;
}
.footer-corporativo{
    display: flex; 
    width: 70%;
    margin: auto; 
}
.icono-footer-info-corporativo{
    width: 7%;
}
.container-corporativo{
    margin-top: 0%;
}
.img-corporativo {
    width: 100%;
    border-radius: 10px;
}
.label-corporativo {
    text-align: center;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
}
.div_list_li {
    list-style: none;
}
.corpo {
    margin-top: 7%;
    padding: 16px 43px !important;
}
.btn-subir-enviar {
    display: block;
    padding: 10px 21px;
    border: 1px solid #fe6313;
    color: white;
    background: #fe6313;
    border-radius: 3px;
    cursor: pointer;
}
.btn-subir-enviar:hover {
    border: 1px solid #fe6313;
    background-color: white;
    color: #fe6313;
    transition: all 0.5s ease-out;
}
/*ESTILOS DEL NAVBAR*/
.cajon-logo{
    margin: 0px;
    padding: 0px;
    display: inline-flex;
}
.navbar-logo{
    background: #ed571d;
    display: flex;
}
.barramenu{
    margin: auto;
    display: flex;
    text-decoration: none;
    padding: 3%;
}
/* FILTROS */
.accordion {
    width: 100%;
    color: white;
    overflow: hidden;
}
.acc-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background-color: rgb(160 163 165 / 25%);
    font-weight: 500;
    cursor: pointer;
}
.accordion:first-child {
    border-bottom: 1px solid;
}
/* .acc-label:hover {
    background-color: #9e1caf;
} */
.acc-label::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\F107";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.4s ease;
}
.acc-content {
    max-height: 0;
    padding: 0 1em;
    color: black;
    transition: all 0.4s ease;
}
.busqueda-inpput {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
.busqueda-inpput[type="checkbox"] + label:before{
    display: none;
}
.busqueda-inpput:checked+.acc-label::after {
    transform: rotate(-90deg);
}
.busqueda-inpput:checked~.acc-content {
    max-height: 100%;
    padding: 1em;
}
/* FIN DE LOS FILTROS */
.titulo_texto {
    color: rgb(0, 58, 126);
}
.title-cotizacion {
    color: #6f6f6f;
    font-size: 32px;
    text-align: center;
    font-family: "Roboto", Sans-serif;
    font-weight: bold;
    padding-bottom: 3%;
}
.contenedor-detalleventa{
    display: flex;
}
.escolar-imagenes{
    padding: 0% 0% 1%;
}
.espacio-contador{
    padding: 4% 0% 2% 0%;
}
.barramenumovil{
    margin: auto;
    display: flex;
    text-decoration: none;
    padding: 3%;
    display: none;
}
.stylomenu{
    color: white;
    font-size: 20px;
    margin-left: 8%;
}
a:hover{
    text-decoration: none;
}
.container-style-contador{
    border: 1px solid #ed571d;
    width: 90%;
    padding: 0%;
    border-radius: 25px;
    margin: 5% auto 3%;
}
.contador-style{
    position: absolute;
    margin-top: -1.5%; 
    width: 100%;
}
.contador-style-productos{
    width: 20%;
    background: #ed571d; 
    margin: auto;
    border-radius: 20px;
    padding: 0.5%;
    display: flex;
    height: 56px; 
}
.categorias-movil{
    font-size: 22px;
    text-decoration: none;
}
.texto-contador-tyle{
    font-size: 14px;
    color: white;
    font-weight: bolder; 
    background: #0e2f7f;
    padding: 11% 4%; 
    border-radius: 20px;
    margin: -5% 0% 0% -5%;
    align-items: center;
    display: flex;
    text-align: center;
    min-inline-size: -webkit-max-content;
    min-inline-size: max-content;
}
.computadora-index{
    padding-left: 0px;
    padding-right: 0px;
}
.tintas-public{
    display: flex; 
    padding-left: 0px; 
    padding-right: 0px; 
}
.tintas-img{
    width: 95%;
}
.laptop-img{
    width: 50%;
    display: flex; 
    justify-content: center; 
}
.laptop-style-derecha{
    justify-content: end;
    display: flex; 
}
.laptop-style-centro{
    justify-content: center;
    display: flex; 
}
.laptop-style-izquierda{
    justify-content: flex-start;
    display: flex; 
}
.enlaces-navbar{
    display: flex;
}
.activo-navbar{
    display: flex;
}
.activo-navbar-secun{
    display: none;
}
.activo-navbar-secun-logo{
    display: none;
}
.center{
    text-align: center;
}
.cuenta-header{
    display: grid;
    color: white;
    margin: 0px 5%;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    position: relative;
}
.start-100{
    left: 70%!important;
}
.contador-carrito{
    margin-top: 3%;
}
.login-box .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: -50px;
    left: calc(50% - 50px);
  }
/* QUITA EL FOCUS Y ACTIVE */
.cuenta-header:focus,
.cuenta-header:active{
    outline: none;
    box-shadow: none;
}
::marker{
    color: #ed571d;
    font-size: 40px;
}
.feat-btn2 {
    padding-left: 60px;
    font-size: 20px; 
    text-decoration: none;
}
.todoproducto{
    position: absolute;
    margin-top: -2.8%; 
    right: 1%;
    border-radius: 5px;
    padding: 0.2% 1% 0.2% 1%;
    background-color: #ed571d;
    border: 1px solid #ed571d;
    color: white;
    text-decoration: none;
}
.todoproducto:hover{
    background-color: hwb(20deg 36% 0%);
    border: 1px solid hwb(20deg 36% 0%);
    color: white;
}
.todoproductoferta{
    position: absolute;
    margin-top: -4%; 
    right: 1%;
    border-radius: 5px;
    padding: 0.2% 1% 0.2% 1%;
    background-color: #ed571d;
    border: 1px solid #ed571d;
    color: white;
    text-decoration: none;
}
.todoproductoferta:hover{
    background-color: hwb(20deg 36% 0%);
    border: 1px solid hwb(20deg 36% 0%);
    color: white;
}
.float{
	position: fixed;
	width: 60px;
	height: 60px;
	bottom: 110px;
	right: 40px;
	background-color: #25d366;
	color: #FFF;
	border-radius: 50px;
	text-align: center;
    font-size: 40px;
	box-shadow: 2px 2px 3px #999;
    z-index: 100;
}
.float:hover{
	color: #FFF;
}
.my-float{
	margin-top: 16px;
}
/* FIN */
.icono-user-header{
    margin: auto auto 0px;
}
.menu-icon {
    height: 25px;
    cursor: pointer;
    width: 40px;
    position: relative;
    overflow: hidden;
    margin: auto;
}
.logo2{
    display: none;
}
.menu-icon:before, .menu-icon:after {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-delay: 0s;
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border-radius: 10px;
    transform-origin: center center;
    background-color: #fff;
}
.menu-icon:before {
    position: absolute;
    top: 0;
    left: 18px;
    transform: rotate(-45deg);
}
.menu-icon:after {
    position: absolute;
    top: 0;
    right: 18px;
    transform: rotate(45deg);
}
.menu-icon .bar {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-delay: 0.2s;
    width: 100%;
    height: 3px;
    background-color: #fff;
    border-radius: 20px;
}
.menu-icon .bar-1 {
    position: absolute;
    top: 2px;
    left: 0;
}
.menu-icon .bar-2 {
    position: absolute;
    top: 10px;
    left: 0;
}
.menu-icon .bar-3 {
    position: absolute;
    top: 18px;
    left: 0;
}
.dropdown-toggle::after{
    color: #ed571d;
}
.menu-icon.opened .bar {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-delay: 0s;
}
.menu-icon.opened:before, .menu-icon.opened:after {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-delay: 0.2s;
}
.menu-icon-3:before {
    transform: rotate(-90deg);
}
.menu-icon-3:after {
    transform: rotate(90deg);
}
.menu-icon-3:before, .menu-icon-3:after {
    top: -2px;
}
.menu-icon-3.opened:before {
    transform: rotate(-45deg);
}
.menu-icon-3.opened:after {
    transform: rotate(45deg);
}
.menu-icon-3.opened:before, .menu-icon-3.opened:after {
    top: 0px;
}
.menu-icon-3.opened .bar {
    opacity: 0;
}
.menu-icon-3.opened .bar-1 {
    top: 10px;
}
.menu-icon-3.opened .bar-3 {
    top: 10px;
}
.buscador-header{
	outline: none;
}
.buscador-header[type=search] {
	border: solid 1px #ccc;
	padding: 10px 10px 9px 45px;
	width: 100%;
	background-color: #fff;
	border-color: #6dcff6;
	border-radius: 10em;
	transition: all .5s;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 1.2%;
}
.buscador-header:-moz-placeholder {
	color: #95a6bb;
}
.buscador-header::-webkit-input-placeholder {
	color: #95a6bb;
}
.caja-buscador-header{
    margin: auto;
}
.info-venta-texto{
    margin: 5% 10%;
    color: #c8c8c8;
    font-size: 17px; 
}
.datos-venta-texto{
    padding: 5% 11% 3% 5%;
    margin-right: 21%;
    color: #c8c8c8;
    font-size: 17px;
}
.tienda-venta-img{
    width: 8%;
    margin: 1% 0% 1% 1%;
}
.tarjeta-venta-img{
    width: 8%;
    margin: 1% 0% 1% 2%; 
}
.container-coti{
    display: flex;
    justify-content: center; 
}
.cantidad-ram{
    font-size: 35px; 
    font-weight: bold; 
    color: #004ba2;
    margin: 3% 0% 0% 9%; 
}
.container-estimado{
    margin: 1% 0% 1% 10%; 
    width: 25%;
}
.container-estimado-doble{
    margin: 1% 0% 1% 20%; 
    width: 50%;
}
.color-texto{
    color: #004ba2; 
    margin: 0% 0% 0% 10%; 
}
.cantidad-ram-doble{
    font-size: 35px; 
    font-weight: bold; 
    color: #004ba2;
    margin: 6% 0% 0% 20%; 
}
/* DISEÑO DE INPUT */
.form__group {
    position: relative;
    padding: 7% 5% 8% 5%;
    width: 100%;
}
.form__field {
    font-family: inherit;
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    outline: 0;
    font-size: 17px;
    padding: 7px 6%;
    background: transparent;
    transition: border-color 0.2s;
}
.form__field::placeholder {
    color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
    font-size: 15px;
    cursor: text;
    top: 20px;
}
.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 17px;
    color: #9b9b9b;
    pointer-events: none;
}
.form__field:focus {
    padding-bottom: 6px;
    font-weight: 500;
    border-width: 2px;
    border-image-slice: 1;
}
.form__field:focus ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 15px;
    color: black;
    font-weight: 700;
}
.modal-content{
    border-radius: 18px;
}
.button-redes-index{
    display: none;
}
.modal-header{
    border: transparent;
    padding-bottom: 0px;
    justify-content: end;
}
.modal-footer{
    border: transparent;
    padding-top: 0px;
}
.modal-header .btn-close{
    padding: 0px;
    margin: 0px;
}
.estilo-button{
    width: 100%;
    background: #ed571d;
    border-radius: 14px;
    color: white;
}
.estilo-button:hover{
    background: #e56e30;
    color: white;
}
.estilo-button:focus,
.estilo-button:active{
    outline: none;
    box-shadow: none;
    background-color: #ed571d;
    color: white;
}
.estilo-button-primary{
    width: 100%;
}
.estilo-button-primary:focus,
.estilo-button-primary:active{
    outline: none;
    box-shadow: none;
    background-color: #198754;
    color: white;
}
/* reset input */
.form__field:required, .form__field:invalid {
    box-shadow: none;
}
.header-principal{
    position: -webkit-sticky;
    position: sticky;
    top: 0; 
    z-index:1111;
}
.btn-carrito-acciones{
    margin: 2% 0% 5%;
}
.title-filtro{
    width: 100%;
    background: white;
    color: black; 
    border-right: transparent; 
    border-left: 1px solid #ed571d; 
    border-bottom: 1px solid #ed571d; 
    border-top: 1px solid #ed571d;
}
.texto-rango-precio{
    width: 100%; 
    font-size: 14px; 
    background: white; 
    color: black; 
    border: 1px solid #ed571d; 
    height: 100%;
}
.filtro-busqueda{
    background: rgb(160 163 165 / 25%); 
    padding: 1%;
    margin: 4% 0% 0%;
    padding-top: 2%; 
}
.button-filtrar{
    display: block;
}
.filtro-movil{
    display: none;
}
.dropdown-menu.show{
    width: 100%;
}
.buton-filtro{
    border: 1px solid #ed571d;
    background-color: #fff;
}
.promo-detalle-producto{
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
}
.precio-normal-detalle{
    text-align: center;
    font-weight: 700; 
}
.precio-normal2-detalle{
    text-align: center;
}
.camion-detalle{
    width: 35%;
}
.tienda-detalle{
    width: 29%;
}
.tarjetas-credito{
    display: flex;
    margin-top: 4%; 
    align-items: center;
}
.banco-pagos{
    display: flex;
    margin-top: 6%; 
    align-items: center;
}
.info-detalle-conten{
    margin: 0%;
}
.producto-nombrestock{
    display: none;
}
.detalle-precio-producto{
    display: flex;
    margin-top: 4%; 
}
.categorias-detalle-producto{
    display: grid;
    padding: 40% 3% 3% 3%; 
}
.metododepago-detalle{
    margin-top: 8%;
    color: #0056b9;
    font-size: 18px;
}
.title-caracteristicas{
    overflow-wrap: anywhere;
    background-color: rgb(240, 244, 247);
    font-weight: bolder;
    font-size: 17px;
    width: 50%;
}
.subtitle-description{
    overflow-wrap: anywhere;
    background-color: rgb(225, 230, 234);
    font-weight: bolder;
    font-size: 17px;
    width: 50%;
}
.title-descripcion-producto{
    font-size: 35px; 
}
.telefonos-web{
    color: white;
    text-decoration: none;
}
.telefonos-web:hover{
    color: #ed571d;
    -webkit-text-decoration: revert;
            text-decoration: revert;
}
.servicios-footer-index{
    display: flex;
    padding: 0px;
}
.espacionombreindex{
    right: 11%;
    position: absolute;
    z-index: 1;
}
.contenedornombreindex{
    background: #ed571d;
    color: white;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    padding: 0% 21%;
    display: inline-flex;
    align-items: center;
}
.contenedornombreindexdetalle{
    background: #ed571d;
    color: white;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    padding: 0% 11%;
    display: inline-flex;
}
.descuentonombreindex{
    font-size: 8px;
    margin-top: -21%;
}
.porcentajenombreindex{
    font-size: 13px;
    display: flex;
}
.numeronombreindex{
    font-size: 24px;
}
.contenporcentaje{
    background: #ed571d;
    color: white;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    padding: 0% 15%;
    display: inline-flex;
    align-items: center;
}
.porcentajenumero{
    font-size: 50px;
}
.porcentajepromo{
    font-size: 24px;
    display: flex;
}
.nombrepromo{
    font-size: 14px;
    margin-top: -21%;
}
.precioofertadetalle{
    font-size: 28px;
    background: #001267;
    padding: 3% 7%;
    border-radius: 10px;
    color: white;
}
.precioonlinedetalle{
    font-size: 18px;
    margin-bottom: 4%;
    color: #0e2f7f;
    line-height: 1;
    text-align: center;
}
.numeroonlinedetalle{
    font-size: 18px;
    color: #ed571d;
}
.precionormaldetalle{
    font-size: 18px;
    margin-bottom: 1%;
    color: #0e2f7f;
    line-height: 1;
    text-align: center;
}
.numeronormaldetalle{
    font-size: 18px;
    color: #ed571d;
}
.caracteristicas-detalle-producto{
    padding: 0% 6%;
    margin-bottom: 4%;
}
.caracteristicas-product-title{
    text-align: center;
    margin-bottom: 2%;
    font-weight: 700; 
    color: #0056b9;
    font-size: 19px; 
}
/* FIN DEL DISEÑO DE INPUT */
.input-contraseña{
    border-right: transparent;
    border-top: 1px solid #0e2f7f;
    border-bottom: 1px solid #0e2f7f;
}
.input-correo{
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border: 1px solid #0e2f7f;
}
.ojo-cotraseña{
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}
.input-group-text{
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background: #0e2f7f;
    border: 1px solid #0e2f7f;
}
.contraseña-campo span {
    text-transform: uppercase;
    cursor: pointer;
    border: 1px solid #0e2f7f;
    align-items: center;
    display: flex;
    padding-right: 3%;
    border-left: transparent;
}
.btn-ingresar-login{
    background: #ed571d;
    padding: 5%;
    width: 70%;
    border-radius: 15px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin: auto;
    justify-content: center;
    display: flex;
    margin-top: 15%;
}
.btn-ingresar-login:hover{
    background: #e960199c;
    color: white;
}
.right{
    margin-top: auto;
}
.btn-google-login{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: #666666;
    width: 70%;
    border-radius: 15px;
    font-size: 18px;
    font-weight: bolder;
    border: 1px solid #ed571d;
    padding: 6%;
    margin: auto;
}
.form-control:focus,
.form-control:active{
    outline: none;
    box-shadow: none;
}
/*FIN DEL NAVBAR*/
/*ESTILOS DEL INDEX*/
.card-header:first-child{
    border-radius: 0px;
}
.nav-tabs .nav-item{
    width: 12%;
    text-align: center;
    margin-right: 0.3%;
    background: #0e2f7f;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    color: #fff;
    background-color: #0e2f7f;
    border-color: #0e2f7f;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
    color: #fff;
    background-color: #0e2f7f;
    border-color: #0e2f7f;
}
.card-header{
    padding: 0.3% 0.8%;
    background: #001267;
    padding-top: 0%;
}
.card-header-tabs{
    margin-right: -0.625rem;
    margin-bottom: -0.3rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}
.nav-tabs .nav-link{
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.primero{
    display: block;
}
.footer-contacto{
    margin-top: 0px;
}
.footer-logo-index{
    margin: 0px;
}
.acerca-nosotros{
    margin-top: 0%;
    padding-left: 0%;
}
.compra-confianza{
    margin-top: 0%;
}
.segundo{
    display: none;
    text-align: center;
    /* font-size: 20px; */
}
.oferta-producto{
    font-weight: 700;
    border: 1px solid #ed571d;
    font-size: 12px;
    background: #ed571d;
    color: white;
    border-radius: 10px;
    line-height: 1.1;
    padding: 2%;
    /*lineHeight: "1.1", padding: "2%"  */
}
.ciento{
    font-size: 19px;
}
.mundo-tecnologico{
    margin-top: 2%;
}
.oferta-ciento{
    width: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 15px;
    background: #0e2f7f;
    color: white;
    z-index: 1;
    height: 100%;
    margin: auto;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 2% 0%;
    /*justifyContent: "center", padding: "2% 0%"  */
}
.precio-general{
    margin-top: 0%;
    font-weight: bolder;
    font-size: 18px;
    line-height: 1;
    /* marginTop: "0%", fontWeight: "bolder", fontSize: "18px", lineHeight: "1"  */
}
.texto-header{
    padding: 0;
    margin: auto 0;
    padding-left: 5%;
    color: #6a99d7;
}
.swiper {
    width: 100%;
    height: 100%;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    /* display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; */
    justify-content: center;
    align-items: center;
}
.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.swiper-slide-prev .contenedor_corporativo{
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper-slide-prev .contenedor_corporativo img{
    width: 80%;
    border-radius: 15px;
    opacity: .5;
}
.swiper-slide-active .contenedor_corporativo img{
    margin: 5% 0% 1%;
    border-radius: 15px;
}
.swiper-slide-next .contenedor_corporativo{
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper-slide-next .contenedor_corporativo img{
    width: 80%;
    border-radius: 15px;
    opacity: .5;
}
.swiper-slide-prev .contenido_corporativo{
    background: #ececec;
    display: none;
}
.swiper-slide-active .contenido_corporativo{
    background: #ececec;
    display: flex;
    margin-top: -6%;
    width: 60%;
    height: 8%;
    position: fixed;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.swiper-slide-next .contenido_corporativo{
    background: #ececec;
    display: none;
}
.swiper {
    margin-left: auto;
    margin-right: auto;
}
.tiraweb img{
    width: 100%;
}
.tirawebonline1{
    display: block;
}
.tirawebonline2{
    display: none;
}
.categorias-principales{
    display: flex;
    height: 75px;
}
.principal-medio{
    align-items: center;
    display: flex;
    justify-content: center;
}
.principal-medio h2{
    font-size: 37px;
    color: white;
    /* transform: scaleY(1.3); */
    margin: 0px;
}
.categorias{
    width: 15%;
    background-color: #eaeaea;
    justify-content: center;
    align-items: center;
    display: flex;
}
.categorias:hover{
    background: #ed571d;
    border-radius: 10px;
    padding-left: 1%;
    transition: 1s ;
    cursor: pointer;
}
.texto-categoria{
    font-size: 17px;
    color: white;
    margin-left: 3%;
    align-items: center;
}
.segundaimagen, 
.texto-categoria{
    display: none;
}
.productos-filtro{
    text-decoration: none;
}
.categorias:hover .segundaimagen,
.categorias:hover .texto-categoria{
    display: flex;
}
.categorias:hover .primerimagen{
    display: none;
} 
.detalle{
    width: 15%;
    background: #ed571d;
}
.detalle img{
    width: 100%;
}
.espacioimagen img{
    padding-right: 1%;
    background-color: white;
    height: 100%;
}
/* .contenedor-index{
    height: 183px;
    margin: 0px;
    padding: 0px;
}
.contenedor-index img{
    width: 100%;
    height: 100%;
} */
.servicios-index{
    display: flex;
    padding: 2% 1% 0%;
    margin-bottom: 2%;
}
.productos-index{
    /* height: 300px; */
    width: auto;
    padding: 20px 19px 10px;
}
.productos-index img{
    width: 75%;
    margin: auto;
    transition: transform .2s;
}
.productos-index img:hover{
    transform: scale(1.1);
}
.titulo-productos-principal{
    text-align: center;
    font-size: 20px;
    color: #0e2f7f;
    margin: 0% 0px 1%;
}
.contenedor-productos-principal-escolar:hover{
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #ed571d;
}
.contenedor-productos-principal-escolar{
    width: 90%;
    margin: auto;
}
.contenedor-productos-index{
    width: 90%;
    margin: auto;
    cursor: pointer;
}
.contenedor-producto-principal{
    width: 90%;
    margin: auto;
}
.contenedor-producto-principal:hover{
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #0e2f7f;
}
.nombre-producto-principal{
    font-size: 15px;
    padding: 0% 5%;
    line-height: 1.1;
    height: 50px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 2%;
    text-align: center;
}
.precio-normal{
    font-size: 11px;
    margin-bottom: 0px;
    margin-top: 2%;
    color: #0e2f7f;
    font-weight: 600;
    display: grid;
}
.precio-online{
    display: inline-flex;
    margin-bottom: 1%;
}
.title-precio-online{
    font-weight: 700;
    line-height: 1.1;
    padding: 2% 1%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #ed571d;
    font-size: 13px;
    background: #ed571d;
    color: #fff;
}
/* .contenedor-productos-principal{
    margin: 0% 3%;
    padding: 4% 0% 1%;
} */
.marco-container{
    padding: 2% 0%;
}
.precio-producto-online{
    width: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0% 0%;
    border: 1px solid #0e2f7f;
    font-size: 20px;
    background: #0e2f7f;
    color: white;
    z-index: 1;
}
.precio-oferta{
    display: inline-flex;
    margin-bottom: 1%;
}
.footer-tira{
    display: contents;
}
.carrito-precios{
    display: flex;
    justify-content: space-between; 
    align-items: end; 
}
.carrito-precios-doble{
    display: flex;
    justify-content: space-between; 
    align-items: end; 
    font-weight: bolder;
    margin-bottom: 1%;
}
.title-precio-oferta{
    font-size: 11px;
    color: #ed571d;
    font-weight: 700;
    line-height: 1;
    padding: 3% 2%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #ed571d;
}
.precio-producto-oferta{
    font-size: 14px;
    width: 100%;
    border-top-right-radius: 5px;
    color: #0e2f7f;
    border-bottom-right-radius: 5px;
    padding: 4% 2%;
    border: 1px solid #0e2f7f;
}
.contenedor_icon:hover .title-precio-oferta, 
.contenedor_icon:hover .precio-producto-oferta{
    background: white;
}
.rallaparaprecio{
    text-decoration: line-through;
}
.tiracentroweb{
    padding: 0% 1%;
    margin: 3% 0% 3%;
    display: block;
}
.tiracentrmovil{
    display: none;
}
.tiracentrowebsubmovil{
    display: none;
}
.tiracentroweb img{
    width: 100%;
}
.mediano{
    display: none;
}
.pequeño{
    display: none;
}
.contenedor-producto-secundario{
    width: 90%;
    margin: auto;
}
.contenedor-producto-secundario:hover{
    border-radius: 5px;
    border: 1px solid #0e2f7f;
}
.marco-productos-secundarios{
    display: flex;
    margin: 0% 3%;
}
.imagen-costado-secundario{
    display: flex;
    justify-content: center;
}
.titulo-productos-nuevos{
    margin: 3% 30%;
    color: #0e2f7f;
    text-align: center;
    font-weight: 800;
    font-size: 20px;
}
.nuevo-productos{
    width: 100%;
}
.nuevo-productos img{
    width: 70%;
    height: 80%;
}
.caja-index{
    display: flex;
}
.tiralarga{
    width: 100%;
    margin: 3% 0%;
    display: block;
}
.tiralargamovil{
    display: none;
}
.tiralagamovil{
    display: none;
}
.laptopsgratis{
    display: block;
}
.laptopsgratismovil{
    display: none;
}
.titulo-redes{
    text-align: center;
    font-weight: 800;
    font-size: 20px;
    color: #0e2f7f;
    margin: 2% 0px 1%;
}
.contenedor-redes{
    display: flex;
    margin: 0% 6%;
}
.contenedor-redes{
    margin-bottom: -2%;
}
.contenedores-redes img{
    width: 90%;
    height: 70%;
}
.contenedor-marcas{
    margin: 3%;
}
.tira-footer{
    width: 100%;
    margin-bottom: 2%;
    display: block;
}
.subtira-footer{
    display: none;
}
.tira-footer img{
    width: 100%;
}
/*FIN DEL INDEX*/
/*ESTILOS DEL FOOTER*/
.contenedor-footer{
    background: #001267;
    height: auto;
    display: flex;
    padding: 1% 10%;
}
.contenedor-footer-corportivo{
    background: #001267;
    height: auto;
    padding: 1% 10%;
}
.footer{
    background: #0e2f7f;
    padding-top: 2%;
    padding-bottom: 2%;
    display: flex;
    overflow-wrap: anywhere;
}
.tirafooter-index{
    display: flex;
    color: white;
    font-size: 14px;
    padding: 1%;
    justify-content: center;
    align-items: center;
}
.tirafooter-corporativo{
    display: flex;
    color: white;
    font-size: 14px;
    padding: 1%;
}
/* .tirafooter-index img{
    height: max-content;
} */
.asesor-texto{
    font-size: 19px;
    margin: auto auto auto 6%;
    font-weight: 800;
    padding: 3%;
    color: #0e2f7f;
    line-height: 1;
}
.precio-promo-detalle{
    width: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 35px;
    background: #0e2f7f;
    color: white;
    margin: auto;
    text-align: center;
    align-items: center;
    display: grid;
    padding: 2%;
}
.promo-detalle-oferta{
    font-weight: 700;
    border: 1px solid #FE6313; 
    font-size: 35px; 
    background: #FE6313; 
    color: white; 
    border-radius: 10px; 
    line-height: 1; 
    text-align: center; 
    padding: 3%; 
}
.digito-detalle{
    font-size: 40px;
}
.medios-de-pago-detalle{
    width: 75%;
}
.precio-tamaño{
    font-size: 20px; 
    color: #0e2f7f;
}
.agregarbolsa{
    padding: 1%;
    background-color: rgb(254, 99, 19);
    color: white;
    width: 70%;
    height: 50px;
}
.agregarbolsa:hover{
    color: white;
    background-color: hwb(20deg 36% 0%);
}
.mensaje-envio{
    padding-left: 3%; 
    font-size: 19px;
    color: #0e2f7f;
    line-height: 1.1; 
}
.recojo-envio{
    padding-left: 6%; 
    font-size: 19px; 
    color: #0e2f7f; 
    line-height: 1.1; 
}
.detalle-contenedor{
    display: flex;
    padding: 2% 6%;
    padding-bottom: 0px; 
}
.preciopaginaweb{
    width: 100%;
    display: flex;
}
.preciosparamovil{
    display: none;
}
.texto-tira-index{
    color: white;
    margin: auto 15px;
    font-size: 16px;
}
.subtexto-tira-index{
    font-weight: 100;
}
.container1-footer-index{
    margin-bottom: 2%;
    display: flex;
    align-items: center;
    padding-left: 13%;
}
.container1-footer-index-corporativo{
    margin-bottom: 0%;
}
.container2-footer-index{
    display: flex;
    align-items: center;
    padding-left: 13%;
}
.container2-footer-corporativo{
    padding-left: 0%;
}
.container-footer-index{
    display: flex;
    align-items: center;
}
.container3-footer-index{
    margin-bottom: 5%;
    display: flex;
    align-items: center;
}
.container4-footer-index{
    display: flex;
    align-items: center;
}
.container4-footer-corporativo{
    align-items: flex-start;
    padding-right: 0%;
}
.container3-footer-index img{
    width: 7%;
}
.container-footer-index img{
    width: 10%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.texto-footer-index{
    margin-bottom: 0px;
    font-size: 11px;
    margin-left: 2%;
    color: white;
    font-weight: 100;
}
.subtexto-footer-index{
    font-weight: 600;
}
.tercertexto-footer-index{
    margin-bottom: 0px;
    font-size: 17px;
    margin-left: 2%;
    color: white;
    font-weight: 600;
}
.direcciones-footer-index{
    width: 100%;
}
.loguito-footer{
    padding: 0px;
}
.loguito-footer img{
    width: 70%;
    float: right;
}
.icon-footer{
    display: flex;
    align-items: end;
}
.sigueno-footer{
    margin-bottom: 0px;
    margin-right: 2%;
    color: white;
    margin-left: 3%;
    font-size: 15px;
}
.iconitos-footer{
    margin-left: 5%;
}
.iconitos-footer img{
    margin-right: 2%;
}
.paginas-footer{
    display: flex;
    font-size: 11px;
    margin-left: 12%;
    text-decoration: none;
}
.paginas-footer:hover{
    -webkit-text-decoration: revert;
            text-decoration: revert;
    color: #ed571d;
}
.paginas-footer span{
    color: white;
}
.paginas-footer span:hover{
    color: #ed571d;
}
.poli-footer{
    display: flex;
    font-size: 11px;
    text-decoration: none;
}
.poli-footer:hover{
    -webkit-text-decoration: revert;
            text-decoration: revert;
    color: #ed571d;
}
.poli-footer span{
    color: white;
}
.poli-footer span:hover{
    color: #ed571d;
}
.title-footer-politica{
    color: white;
    margin-bottom: 10%;
    font-size: 12px;
}
.horatencion-footer-politica{
    color: white;
    /* margin-bottom: 5%; */
    font-size: 15px;
    margin-left: 5%;
}
.hora-atencion-footer{
    color: white;
}
.hora-atencion-footer span{
    display: flex;
    font-size: 11px;
}
.atencionfooter-index{
    padding-left: 4%;
    margin-top: 0%;
    padding-right: 13%;
}
.numeroatencion-footer{
    margin-bottom: 2%;
}
.trabaja-footer{
    margin-top: 5.8%;
}
.trabaja-footer a p{
    margin-bottom: 0px;
    padding: 3px;
    font-weight: 700;
    background: #ed571d;
    color: white;
    width: 52%;
    text-align: center;
    border-radius: 5px;
    display: block;
    margin-left: 5%;
    font-size: 11px;
}
.metododepago-footer{
    margin-top: 5%;
}
.metododepago-footer img{
    width: 60%;
    /* display: flex;
    margin: auto; */
}
/*FIN DEL FOOTER*/

table { 
    border-collapse: separate;
    border-left: 0;
    border-radius: 4px;
    border-spacing: 0px;
    width: 100%;
}
th, td {
    padding: 0.6% 4% 0.6% 4%;
    text-align: left;
    vertical-align: top;
    /* border-left: 1px solid #fff; */
}
td {
    border-top: 1px solid #fff;
    border-bottom: 2px solid #fff;
    font-size: 17px;
}
tbody:first-child tr:first-child td:first-child {
    border-radius: 4px 0 0 0;
}
tbody:last-child tr:last-child td:first-child {
    border-radius: 0 0 0 4px;
}

/* DETALLE DE VENTA */
.title-name-detalle{
    font-size: 19px;
    margin-bottom: 4px;
}
.input-detalle{
    border-radius: 12px;
}
.input-cupon{
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}
.btn-cupon{
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}
.titulo-cupon{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 2px;
    color: #ed571d;
    margin-left: 1%;
}
.metododepago{
    display: flex;
    margin-top: 4%;
}
.titulopago{
    font-weight: bolder;
    color: #ed571d;
}
.imgpago{
    width: 4%;
    margin-left: 1%;
}
.checkbox-detalle{
    border: 1px solid rgba(0,0,0,.25);
    border-radius: 12px;
    margin-top: 2%;
    padding: 0% 3%;
}
.checkbox-detalle:hover{
    border: 1px solid #ed571d;
    cursor: pointer;
}
.check-detalle{
    font-size: 20px;
    padding: 1% 6%;
    align-items: center;
    display: flex;
}
.pagocontarjeta{
    margin: 0% 4% 0% 1%;
    font-size: 19px;
}
.subtitutlo-venta{
    font-size: 14px;
    margin-left: 7%;
    color: #c8c8c8;
}
.form-check-input:focus,
.form-check-input:active{
    outline: none;
    box-shadow: none;
}
input[type="radio"]{
    cursor: pointer;
}
input[type="radio"]:checked::after{
    display: flex;
    content: "";
    position: relative;
    height: 100%;
    width: 100%;
    background: #ed571d;
    border: 1px solid #ed571d;
    border-radius: 50%;
}
input[type="radio"]:focus,
input[type="radio"]:active,
input[type="radio"]:checked{
    border: 1px solid #ed571d;
}
input[type="checkbox"] + .terminos-condiciones-venta{
    color: black;
}
input[type="checkbox"] + label:before{
    margin-top: 0.4%;
}
.btn-pedido{
    background: #ed571d;
    width: 59%;
    padding: 2% 0%;
    border-radius: 14px;
    color: white;
    font-size: 19px;
}
.btn-pedido:hover{
    background: #e56e30;
    color: white;
}
.reduccantidadetalle{
    color: #0056b9; 
    border-color: #0056b9;
    border-radius: 5px; 
    background-color: transparent; 
}
.reduccantidadetalle:hover{
    color: white;
    border-color: #0056b9;
    background-color: #0056b9;
    transition: 1s;
}
.addcantidadetalle{
    color: #0056b9;
    border-color: #0056b9; 
    border-radius: 5px;
    background-color: transparent; 
}
.addcantidadetalle:hover{
    color: white;
    border-color: #0056b9;
    background-color: #0056b9;
    transition: 1s;
}
/*FIN DEL DETALLE DE VENTA*/
/*******************CARRITO*****************/
.conten-cart{
    margin: 5% 0% 3%;
    display: flex;
    justify-content: center;
}
.car-title{
    font-size: 35px;
    font-weight: bolder;
    color: #0e2f7f;
}
.titulo-carrito{
    text-align: center;
    color: #0e2f7f;
    font-size: 18px;
}
th, .estilos-carrito {
    width: 150px;
    text-align: center;
    border-bottom: 1px solid #ed571d;
    padding: 5px;
    border-top: 1px solid #ed571d;
    vertical-align: inherit;
    color: #6e6969cf;
    font-size: 19px;
}
.gfg {
    border-collapse:separate;
    border-spacing:0 15px;
}
.resumen-cart{
    margin: 6% 0% 1%;
    display: flex;
    justify-content: center;
}
.btn-resumen{
    background: #ed571d;
    width: 50%;
    color: white;
    font-size: 19px;
}
.btn-resumen:hover{
    background: #e56e30;
    color: white;
}
.btn-stock{
    background: red;
    width: 50%;
    color: white;
    font-size: 19px;
}
.btn-stock:hover{
    background: red;
    color: white;
}
.comprar-resumen{
    color: black;
    margin-left: 3%;
    font-size: 19px;
}
li{
    display: block;
    transition-duration: 0.5s;
}
li:hover {
    cursor: pointer;
}
ul li ul {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: all 0.5s ease;
    margin-top: 1rem;
    left: 0;
    display: none;
}
ul li:hover > ul,
ul li ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
}
ul li ul li {
    clear: both;
    width: 100%;
}
/*RADIO BUTTON PARA FORMULARIO*/
.formulario-cotizacion-search{
    border: 1px solid #00000040;
    border-radius: 5px;
    width: 100%;
    margin: 0% 5%;
}
.myradio {
    display: inline-block;
    border-radius: 999px;
    margin: 5px;
}
.myradio__input {
    opacity: 0;
    position: absolute;
}
.myradio__label {
    border-radius: 9999px;
    padding: 4px 15px 5px 33px;
    cursor: pointer;
    position: relative;
    transition: all 0.5s;
    margin-bottom: 0px;
    font-size: 22px;
    align-items: self-end;
    display: flex;
    font-weight: bold;
}
.myradio__label::before, .myradio__label::after {
    content: "";
    border-radius: 9999px;
    width: 16px;
    height: 16px;
    margin: 5px 0;
    position: absolute;
    z-index: 1;
}
.myradio__label::before {
    background-color: #DCDCDC;
    border: 2px solid #DCDCDC;
    top: 9px;
    left: 10px;
    transition: all 0.5s;
}
.myradio__label::after {
    background-color: #ffffff;
    top: 11px;
    left: 12px;
    transition: all 0.15s;
    width: 13px;
    transition-timing-function: ease-out;
    height: 13px;
}
.myradio__label:hover {
    background-color: rgba(255, 123, 0, 0.89);
    color: white;
}
.myradio__label:hover::before {
    border: 2px solid #ed571d;
}
.myradio__input:checked ~ .myradio__label::before {
    background-color: #ed571d;
    border: 2px solid #ed571d;
}
.myradio__input:checked ~ .myradio__label::after {
    width: 8px;
    height: 8px;
    top: 13px;
    left: 14px;
}
.texto-coti{
    font-size: 12px;
    margin: 0px;
    display: block;
}
.agregados-coti{
    text-align: start;
    width: 100%;
}
.tipousoformulario{
    padding: 0 12%; 
    justify-content: space-around;
    display: flex;
    margin: 2% 0%;
    margin-bottom: 4%;
}
.estilo-agregados{
    justify-content: space-around;
    display: flex;
}
/*FIN DE DISEÑO PARA FORMULARIO*/
/* VISTA DE MI TIEDA */
.logo-tiendas {
    width: 40%;
}
.maps-tiendas {
    margin: 0%;
    padding-bottom: 4%;
}
.titulo-tiendas {
    text-align: center;
    padding: 5% 5% 2%;
    color: #ed571d;
}
.maps-icon-tiendas {
    width: 4%;
}
/* FIN DE MI TIENDA */
/* VISTA DE MIS CUENTAS */
.titulo-transferencia{
    text-align: center;
    text-transform: uppercase;
    padding: 4% 0% 0% 0%;
    color: #ed571d;
    font-weight: bold;
}
.fila-trans {
    margin: 4% 0% 3% 0%;
    justify-content: center;
}
.border-img-trans {
    width: 50%;
    margin: 0 auto;
}
.texto-cuenta-nunmeros {
    text-align: center;
    margin-top: 5%;
}
.texto-prueba {
    display: block;
}
.subtitulo-transferencia {
    color: #003a7e;
    text-align: center;
}
.cuentas-bancarias-view {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3%;
}
.texto-view {
    justify-content: flex-end;
    display: grid;
}
.whatsapp-view {
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    cursor: pointer;
    padding: 2%;
}
.whatsapp-number {
    font-size: 40px;
    color: #25d366;
}
/* FIN DE LA VISTA MIS CUENTAS */

.dropdown {
    position: relative;
    width: 100%;
    filter: url(#goo);
}
.dropdown__face, .dropdown__items {
    background-color: #fff;
    padding: 20px;
    border-radius: 25px;
}
.dropdown__face {
    display: block;
    position: relative;
}
.dropdown__items {
    margin: 0;
    position: absolute;
    right: 0;
    top: 50%;
    width: 100%;
    list-style: none;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    visibility: hidden;
    z-index: -1;
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.93, 0.88, 0.1, 0.8);
}
.dropdown__items::before {
    content: "";
    background-color: #fff;
    position: absolute;
    bottom: 100%;
    right: 20%;
    height: 40px;
    width: 20px;
}
.dropdown__arrow {
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
    position: absolute;
    top: 50%;
    right: 30px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg) translateY(-50%);
    transform-origin: right;
}
.dropdown  #dropdown {
    display: none;
}
.dropdown  #dropdown:checked ~ .dropdown__items {
    top: calc(100% + 25px);
    visibility: visible;
    opacity: 1;
}
#dropdown[type="checkbox"] + label:before{
    border: transparent;
}
.swiper-wrapper{
    display: flex;
    align-items: center;
}
details {
    width: min(12rem, 100%);
    align-self: flex-start;
}
summary {
    cursor: pointer;
}
details > details,
summary + div {
    padding-left: 1rem;
}

nav > details {
    background-color: hsl(0, 0%, 86.3%, 1);
}
.mainmenu {
    padding: 0.5rem 1rem;
    border-radius: 1.25em;
    border: 1px solid hsl(0, 0%, 76.3%, 1);
}
.abajo {
    padding-bottom: 3%;
}
.caja-manual-pasos {
    margin: 3% 0%;
}
.contenedor-total {
    border: 2px solid #f1f1f1;
    padding: 4%;
    border-radius: 5px;
    height: 100%;
}
.title-manual {
    font-weight: bold;
    text-transform: uppercase;
}
.text-manual {
    text-align: justify;
    padding: 0% 10%;
}
/**************************************** RECLAMO *************************************************************/
.container-reclamo{
    display: flex;
    justify-content: center;
}
.formu-lario{
    border: 1px solid #cfd8dc;
    margin: 0% 3% 2% 3%;
    border-radius: 10px;
}
.titulo-reclamo{
    margin: 5% 5%;
    font-size: 25px;
    text-transform: uppercase;
}
.espacio-formulario{
    margin: 6% 5%;
}

@media screen and (max-width: 1902px){
    .card-header{
        padding: 0.4%;
    }
    .card-header-tabs{
        margin-right: 0;
    }
}
@media screen and (max-width: 1832px){
    .precio-promo-detalle{
        font-size: 30px;
    }
}
@media screen and (max-width: 1810px){
    .asesor-texto{
        line-height: 1;
        padding: 0px;
    }
}
@media screen and (max-width: 1808px){
    .precioofertadetalle{
        font-size: 25px;
    }
    .precioonlinedetalle, .numeroonlinedetalle,
    .precionormaldetalle, .numeronormaldetalle{
        font-size: 15px;
    }
}
@media screen and (max-width: 1738px){
    .texto-contador-tyle{
        padding: 12% 4%;
        margin: -6% 0% 0% -5%;
    }
}
@media screen and (max-width: 1707px){
    .precio-promo-detalle{
        font-size: 30px;
    }
    .cajadetexto_corporativo {
        font-size: 16px;
        padding-left: 6%;
    }
}
@media screen and (max-width: 1694px){
    .promo-detalle-oferta,
    .precio-promo-detalle{
        font-size: 25px;
    }
    .digito-detalle{
        font-size: 30px;
    }
    .agregarbolsa{
        width: 100%;
    }
}
@media screen and (max-width: 1671px){
    .contador-style{
        position: relative;
    }
    .contador-style-productos{
        width: 25%;
        padding: 0.6%;
    }
    /* .contenedor-productos-principal{
        padding: 2% 0% 1%;
    } */
    .espacio_productos_promo{
        padding: 2% 3% 1%;
    }
    .cajadetexto_corporativo{
        font-size: 15px;
    }
}
@media screen and (max-width: 1662px){
    .precio-tamaño{
        font-size: 15px;
    }
}
@media screen and (max-width: 1623px){
    .precioofertadetalle {
        font-size: 20px;
    }
    .precioonlinedetalle, .numeroonlinedetalle, 
    .precionormaldetalle, .numeronormaldetalle {
        font-size: 12px;
    }
}
@media screen and (max-width: 1602px){
    .medios-de-pago-detalle{
        width: 65%;
    }
}
@media screen and (max-width: 1587px){
    .promo-detalle-oferta,
    .precio-promo-detalle{
        font-size: 25px;
    }
    .digito-detalle{
        font-size: 30px;
    }
    .agregarbolsa{
        width: 100%;
    }
}
@media screen and (max-width: 1563px){
    .cajadetexto_corporativo {
        font-size: 14px;
    }
}
@media screen and (max-width: 1532px){
    .precio-tamaño{
        font-size: 15px;
    }
    /* .precio-normal{
        font-size: 9px;
    } */
    
    .cajadetexto_corporativo {
        font-size: 13px;
        padding-left: 10%;
    }
}
@media screen and (max-width: 1479px){
    .contador-style-productos{
        width: 29%;
        padding: 0.8%;
    }
}
@media screen and (max-width: 1460px){
    .card-header{
        padding: 0.6%;
    }
    .cajadetexto_corporativo {
        font-size: 11px;
    }
}
@media screen and (max-width: 1443px){
    .asesor-texto{
        font-size: 15px;
    }
}
@media screen and (max-width: 1400px){
    .mensaje-envio,
    .recojo-envio{
        font-size: 12px;
    }
}
@media screen and (max-width: 1369px){
    /* .precio-normal{
        font-size: 9px;
    } */
    .precio-tamaño{
        font-size: 12px;
    }
    .title-filtro,
    .texto-rango-precio{
        font-size: 11px;
    }
    .cajadetexto_corporativo{
        font-size: 10px;
    }
}
/* @media screen and (max-width: 1299px){
    .precio-normal{
        font-size: 8px;
    }
} */
@media screen and (max-width: 1315px){
    .precioofertadetalle {
        font-size: 15px;
    }
    .precioonlinedetalle, .numeroonlinedetalle, 
    .precionormaldetalle, .numeronormaldetalle {
        font-size: 9px;
    }
}
@media screen and (max-width: 1259px){
    .contador-style-productos{
        width: 33%;
        margin-top: -3%;
    }
    .ButtonCoti{
        width: 20%;
    }
}
@media screen and (max-width: 1200px){
    .label-corporativo{
        font-size: 10px;
    }
    .activo-navbar{
        display: none;
    }
    .barramenumovil{
        display: flex;
    }
    .barramenu{
        display: none;
    }
    .navbar_corporativo_flotante{
        margin-top: -3%;
    }
    .start-100{
        left: 64%!important;
    }
    .activo-navbar-secun{
        display: flex;
    }
    .titulo_corporativo{
        font-size: 15px;
    }
    .activo-navbar-secun-logo{
        background: white;
        justify-content: center;
        display: flex;
        align-items: center;
        padding: 1% 0%;
    }
    .stylomenu{
        font-size: 19px;
    }
    .caja-buscador-header{
        padding: 1.5%;
    }
    .titulo-productos-nuevos{
        margin: 3% 0%;
    }
    .loguito-footer img{
        width: 100%;
    }
    .trabaja-footer a p{
        width: 100%;
    }
    .btn-carrito-acciones{
        margin: 2% 0% 5%;
        display: grid;
        justify-content: center;
    }
    .btn-resumen{
        width: 100%;
        /* margin-bottom: 10%; */
    }
    .info-venta-texto{
        margin: 5% 9% 2%;
    }
    .datos-venta-texto{
        padding: 0% 13% 3% 9%;
    }
    .tienda-venta-img,
    .tarjeta-venta-img{
        width: 5%;
    }
    .detalle-contenedor{
        display: block; 
    }
    .precio-promo-detalle,
    .promo-detalle-oferta{
        font-size: 35px;
    }
    .digito-detalle{
        font-size: 40px;
    }
    .promo-detalle-producto{
        display: flex;
    }
    .precio-normal-detalle{
        text-align: end;
        padding-right: 5%;
    }
    .precio-normal{
        font-size: 11px;
    }
    .precio-tamaño{
        font-size: 20px;
    }
    .precio-normal2-detalle{
        text-align: start;
        padding-left: 6%;
    }
    .asesor-texto,
    .mensaje-envio, 
    .recojo-envio{
        font-size: 19px;
    }
    .camion-detalle{
        width: 30%;
    }
    .tienda-detalle{
        width: 24%;
    }
    .medios-de-pago-detalle{
        width: auto;
    }
    .tarjetas-credito,
    .banco-pagos{
        justify-content: space-between;
    }
    .info-detalle-conten{
        margin: 2% 0% 0%;
    }
    .detalle-producto-nombrestock{
        display: none;
    }
    .producto-nombrestock{
        display: block;
        margin-bottom: 8%;
        padding: 0% 2%;
        margin-top: 5%;
    }
    .contenedor_contacto{
        padding-top: 0%;
    }
    /* .precio-normal{
        font-size: 7px;
    } */
}
@media screen and (max-width: 1199px){
    .precioofertadetalle{
        font-size: 28px;
    }
    .precioonlinedetalle, .numeroonlinedetalle, 
    .precionormaldetalle, .numeronormaldetalle{
        font-size: 18px;
    }
}
@media screen and (max-width: 1117px){
    .cajadetexto_corporativo{
        font-size: 9px;
    }
}
@media screen and (max-width: 1111px){
    .contador-style-productos{
        width: 36%;
    }
    .texto-contador-tyle{
        padding: 11% 4%;
        margin: -5% 0% 0% -5%;
    }
}
@media screen and (max-width: 1100px){
    .button-filtrar{
        display: none;
    }
    .preciopaginaweb{
        display: none;
    }
    .preciosparamovil{
        display: flex;
        margin: auto;
    }
    .filtro-movil{
        display: flex;
    }
    /* .precio-normal{
        font-size: 6px;
    } */

    .tipouso, .cotizaciones, 
    .estilocoti, .tiprocesador, .presu{
        font-size: 20px;
    }
}
@media screen and (max-width: 1018px){
    .contador-style-productos{
        width: 40%;
        padding: 1%;
    }
    .categorias-principales{
        display: none;
    }
}
@media screen and (max-width: 1008px){
    .primero{
        display: none;
    }
    .segundo{
        display: block;
        padding-left: 0%;
        margin: 1% 0%;
    }
    .nav-tabs .nav-item{
        width: auto;
        margin-bottom: 0%;
    }
    .titulo_corporativo{
        font-size: 10px;
    }
    .slider_corporativo{
        display: block;
    }
    .contexto_info{
        font-size: 10px;
    }
    .punto_info{
        width: 20px;
        height: 20px;
    }
}
@media screen and (max-width: 993px){
    .contenedor-detalleventa{
        display: block;
    }
    .cajadetexto_corporativo{
        font-size: 8px;
    }
}
@media screen and (max-width: 991px){
    .tipousoformulario{
        padding: 0%;
    }
    .costado{
        padding-left: 0%;
    }
    .servicios-index{
        display: block;
    }
    .servicios-footer-index{
        margin-bottom: 2%;
    }
    .metododepago-footer img {
        width: 30%;
        margin-left: 5%;
    }
    .contenedor-footer{
        display: flex;
    }
    .navbar_corporativo_flotante{
        margin-top: -4%;
    }
    .contenedor_contacto{
        padding-top: 5%;
    }
    .telefono_contacto{
        width: 100%;
    }
    .contacto_bloque{
        margin: 2% 0%;
    }
    .input_contacto{
        width: 85%;
        padding-top: 3%;
    }
    .button_contacto{
        width: 85%;
        padding-top: 2%;
    }
    .formulario_corporativo{
        padding: 1% 8%;
    }
    .boton_de_enviar{
        padding: 1%;
    }
    .contenedor-footer-corportivo{
        display: flex;
    }
    .footer-corporativo{
        width: 100%;
    }
    .container2-footer-corporativo{
        padding-left: 13%;
    }
    .container4-footer-corporativo{
        align-items: center;
        padding-right: 11%;
    }
    .container-corporativo{
        margin-top: 5%;
    }
    .label-corporativo{
        font-size: 7px;
    }
    .especificaciones-corporativo{
        font-size: 8px;
    }
    .vermas_corporativo button{
        font-size: 9px;
    }
    .imagen-corporativo{
        border-radius: 15px;
    }
    .contacto{
        width: 100%;
        margin-top: 2%;
        margin-bottom: 2%;
    }
    .contacto img{
        width: 14%;
    }
    .footer-tira{
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .tirafooter-index{
        display: block;
        text-align: center;
        padding: 6% 4%;
    }
    .tirafooter-corporativo{
        display: block;
        text-align: center;
        padding: 6% 3%;
    }
    .espacio-arriba{
        padding-top: 8%;
    }
    .tirafooter-index img{
        width: 13%;
        margin: 2% 0%;
    }
    .tirafooter-corporativo img{
        width: 13%;
        margin: 2% 0%;
    }
    .texto-tira-index{
        font-size: 13px;
    }
    .footer{
        display: grid;
    }
    .footer-contacto{
        margin-top: 2%;
    }
    .footer-logo-index{
        margin: 2% 0%;
    }
    .acerca-nosotros{
        margin-top: 5%;
        padding-left: 8%;
    }
    .compra-confianza{
        margin-top: 5%;
    }
    .atencionfooter-index{
        margin-top: 4%;
        padding-left: 13%;
    }
    .trabaja-footer a p{
        width: 52%;
    }
    .loguito-footer img{
        width: auto;
    }
    .horatencion-footer-politica{
        margin-bottom: 2%;
        font-size: 13px;
    }
    .texto-footer-index,
    .subtexto-footer-index,
    .paginas-footer,
    .title-footer-politica,
    .poli-footer,
    .hora-atencion-footer span,
    .texto-atencion-tienda,
    .tercertexto-footer-index{
        font-size: 13px;
    }
    .sigueno-footer{
        font-size: 12px;
    }
    .iconitos-footer img{
        margin-right: 3%;
    }
    .icono-footer-info,
    .container3-footer-index img{
        width: 7%;
    }
    .icono-footer-info-corporativo{
        width: 11%;
    }
    .footer{
        padding-top: 4%;
    }
    .container-coti{
        display: block;
    }
    .cantidad-ram,
    .cantidad-ram-doble{
        margin: 4% 0% 0% 3%;
    }
    .container-estimado,
    .container-estimado-doble{
        margin: 3% 0% 1% 2%;
        width: 50%;
    }
    .color-texto{
        margin: 0%;
    }
    .ramygraficos, .prueba{
        display: block;
    }
    .oculto {
        display: none;
    }
    .linederam, .lineaestilo{
        display: none;
    }
}
@media screen and (max-width: 921px){
    .contador-style-productos{
        width: 44%;
    }
    .todoproducto{
        margin-top: -3.5%;
    }
    .cajadetexto_corporativo {
        font-size: 7px;
    }
}
@media screen and (max-width: 823px){
    .contador-style-productos{
        width: 50%;
    }
    .texto-contador-tyle{
        padding: 10% 4%;
        margin: -4% 0% 0% -5%;
    }
    .ButtonCoti{
        width: 25%;
    }
    .cajadetexto_corporativo {
        font-size: 6px;
    }
}
@media screen and (max-width: 769px){
    .escolar-imagenes{
        padding: 0% 0% 4%;
    }
    .tira-footer{
        display: none;
    }
    .subtira-footer{
        display: block;
        width: 100%;
        margin-bottom: 1%;
    }
    .tiralarga{
        display: none;
    }
    .tiralargamovil{
        width: 100%;
        margin: 3% 0%;
        display: block;
    }
    .tiralagamovil{
        display: block;
        width: 100%;
        margin: 3% 0%;
        margin-top: 0px;
    }
    .laptopsgratis{
        display: none;
    }
    .laptopsgratismovil{
        display: block;
    }
    .tiracentroweb{
        display: none;
    }
    .tiracentrowebsubmovil{
        display: block;
    }
    .tiracentrmovil{
        padding: 0% 1%;
        margin: 3% 0% 3%;
        display: block;
    }
    .maps_corporativo_trujillo{
        margin: 0px;
        padding: 0px;
    }
    .maps_corporativo_lima{
        margin: 0px;
        padding: 0px;
    }
    .maps_corporativos{
        display: block;
    }
    .menu_corporativo{
        display: none;
    }
    .menu_corporativo_movil{
        display: flex;
        align-items: center;
    }
    .navbar_corporativo{
        color: black;
        text-decoration: none;
    }
    .navbar_corporativo:hover{
        color: black;
    }
    .logo_corporativo{
        width: 100%;
    }
    .espacio-contador {
        padding: 4% 0% 2% 0%;
    }
    .ButtonCoti{
        width: 30%;
    }
    .titulo_corporativo {
        font-size: 7px;
    }
    .telefono_contacto{
        width: 120%;
        margin-right: -31%;
    }
    .formulario_corporativo{
        font-size: 14px;
    }
    .button_contacto{
        padding-top: 4%;
    }
    .container-style-contador{
        margin: 8% auto 3%;
    }
    .tirawebonline1{
        display: none;
    }
    .tirawebonline2{
        display: block;
        height: 35px;
    }
    .espaciointel{
        margin: 3% 0%;
    }
    .tipousoformulario{
        display: block;
    }
    .estilolibre{
        display: flex !important;
    }
    .tipodeoficio{
        margin: 0% 0% 5% 0%;
    }
    .tiradeimagenes{
        height: 11%;
    }
    .margenlaptop, .margenpc{
        height: 100px;
    }
    .laptopopc{
        margin-top: 3%;
        margin-bottom: 3%;
    }
    .espaciobajo{
        margin-bottom: 4%;
    }
    .espacio-movil{
        margin-top: 5%;
    }
    .minitipoprocesador{
        display: block;
    }
    .carrito-precios,
    .carrito-precios-doble{
        width: 50%;
    }
    .categorias-movil{
        font-size: 16px;
    }
    ::marker{
        font-size: 30px;
    }
    .feat-btn2{
        font-size: 15px;
    }
    .mediano{
        display: block;
    }
    .pequeño{
        display: none;
    }
    .tarjetitas-venta-img{
        width: 45%;
    }
    .titulo-productos-principal{
        font-size: 15px;
    }
    .mundo-tecnologico{
        margin-top: 4%;
    }
    .computadora-index{
        margin: 4% 0%;
        padding-left: 3%;
        padding-right: 3%;
    }
    .caja-index{
        display: block;
    }
    .nuevo-productos{
        margin-bottom: 4%;
    }
    .contenedor-redes{
        display: none;
    }
    .button-redes-index{
        display: flex;
        margin-top: 3%;
    }
    .redesmovil{
        width: 50%;
    }
    .redes-index{
        display: flex;
        justify-content: center;
        margin-bottom: 2%;
    }
    .btn-tiktok{
        background-color: #000;
        color: white;
        border-radius: 1px solid #000;
    }
    .btn-tiktok:hover{
        background-color: #000;
        color: white;
        border-radius: 1px solid #000;
    }
    .btn-instagram{
        background-color: #DD2A7B;
        color: white;
        border-radius: 1px solid #DD2A7B;
    }
    .btn-instagram:hover{
        background-color: #DD2A7B;
        color: white;
        border-radius: 1px solid #DD2A7B;
    }
    .btn-facebook{
        background-color: #3B5998;
        color: white;
        border-radius: 1px solid #3B5998;
    }
    .btn-facebook:hover{
        background-color: #3B5998;
        color: white;
        border-radius: 1px solid #3B5998;
    }
    .btn-youtube{
        background-color: red;
        color: white;
        border-radius: 1px solid red;
    }
    .btn-youtube:hover{
        background-color: red;
        color: white;
        border-radius: 1px solid red;
    }
    .contador-style-productos{
        width: 55%;
        padding: 1.4%;
        margin-top: -5%;
    }
    /* .texto-contador-tyle{
        padding: 12% 4%;
        margin: -7% 0% 0% -5%;
    } */
    .mtop{
        padding: 4% 0% 3%;
    }
    .tintas-public{
        display: flex;
        padding-right: calc(var(--bs-gutter-x) * .5);
    }
    .tintas-img{
        width: 95%;
    }
    .laptop-img{
        width: 50%;
        display: flex; 
        justify-content: end; 
    }
    .laptop-style-derecha{
        justify-content: center;
    }
    .laptop-style-izquierda{
        justify-content: center;
    }
    .container3-footer-index img{
        width: 7%;
    }
    .productos-index{
        padding: 10px 0px 10px;
    }
    .segundo{
        font-size: 7px;
    }
    .table{
        max-width: -webkit-max-content;
        max-width: max-content;
        width: -webkit-max-content;
        width: max-content;
    }
    .gfg{
        width: -webkit-max-content;
        width: max-content;
    }
    /* .contenedor-productos-principal,
    .marco-container{
        padding: 5% 0% 6%;
        margin: 0%;
    } */
    .medios-de-pago-detalle{
        width: auto;
        margin-left: 2%;
    }
    .tarjetas-credito,
    .banco-pagos{
        justify-content: flex-start;
    }
    .detalle-precio-producto{
        display: block;
        margin-top: 6%;
    }
    .tienda-detalle{
        width: 20%;
    }
    .camion-detalle{
        width: 25%;
    }
    .categorias-detalle-producto{
        padding: 6% 0% 3%;
    }
    .metododepago-detalle{
        margin-top: 2%;
    }
    .title-caracteristicas{
        font-size: 15px;
    }
    .subtitle-description{
        font-size: 15px;
    }
    .title-descripcion-producto{
        font-size: 20px;
    }
    .caracteristicas-detalle-producto{
        margin-bottom: 8%;
    }
    .caracteristicas-product-title{
        margin-bottom: 4%;
    }
    .precio-promo-detalle,
    .promo-detalle-oferta{
        font-size: 30px;
    }
    .digito-detalle {
        font-size: 30px;
    }
    .precio-normal-detalle{
        padding-right: 0%;
    }
}
@media screen and (max-width: 767px){
    .fondo-cotizaciones{
        padding: 50px 30px 60px;
    }
    .navbar_corporativo_flotante{
        margin-top: -5%;
    }
    .label-corporativo{
        font-size: 14px;
    }
    .vermas_corporativo button{
        font-size: 15px;
    }
    .pantalla-grande{
        display: none;
    }
    .especificaciones-corporativo{
        font-size: 16px;
    }
    .pantalla-movil{
        display: block;
    }
    .icono-footer-info-corporativo {
        width: 8%;
    }
    .container2-footer-corporativo {
        padding-left: 0%;
        margin-bottom: 5%;
    }
    .computadora-index-publi{
        margin-bottom: 2%;
    }
}
@media screen and (max-width: 680px){
    .segundo {
        margin: 1% 0%;
    }
    .nav-tabs .nav-item{
        margin-bottom: 0.4%;
    }
    .tiraweb{
        margin-top: -0.5%;
    }
    .espacionombreindex {
        right: 12%;
    }
}
@media screen and (max-width: 668px){
    .cajadetexto_corporativo{
        font-size: 5px;
    }
}
@media screen and (max-width: 659px){
    .contador-style-productos{
        width: 60%;
    }
    .navbar_corporativo_flotante{
        margin-top: -6%;
    }
}
@media screen and (max-width: 625px){
    .asesor-texto,
    .mensaje-envio,
    .recojo-envio{
        font-size: 15px;
    }
}
@media screen and (max-width: 628px){
    /* .grande{
        display: none;
    } */
    .mediano{
        display: none;
    }
    .pequeño{
        display: block;
    }
    .oferta-producto{
        font-size: 15px;
    }
    .ciento{
        font-size: 15px;
    }
    .oferta-ciento{
        font-size: 15px;
    }
    .precio-general{
        font-size: 11px;
    }
    .contador-style-productos{
        width: 65%;
    }
}
@media screen and (max-width: 600px){
    .logo{
        display: none;
    }
    .logo2{
        display: block;
    }
}
@media screen and (max-width: 590px){
    .tiraweb{
        margin-top: -1%;
    }
    .banners-index{
        margin-top: -1%;
    }
    .cajadetexto_corporativo {
        font-size: 5px;
    }
    .porcentajenumero{
        font-size: 40px;
    }
    .porcentajepromo{
        font-size: 20px;
    }
}
@media screen and (max-width: 576px){
    .container1-footer-index, 
    .container3-footer-index,
    .container2-footer-index,
    .container4-footer-index{
        padding: 0px;
        margin-bottom: 5%;
    }
    .espaciofooter{
        margin-left: 7%;
        margin-bottom: 5%;
    }
    .container1-footer-index-corporativo{
        margin-bottom: 5%;
    }
    .loguito-footer img{
        float: none;
    }
    .paginas-footer{
        margin-left: 0px;
    }
    .acerca-nosotros{
        padding-left: 20%;
    }
    .atencionfooter-index{
        padding-left: 20%;
        margin-top: 8%;
    }
    .icono-footer-info-corporativo{
        width: 7%;
    }
    .horatencion-footer-politica{
        margin-bottom: 3%;
    }
    .compra-confianza{
        padding-left: 20%;
        margin-top: 8%;
    }
    .container-telefonos{
        padding-left: 20%;
    }
    .loguito-footer{
        justify-content: center;
        display: flex;
        margin-top: 0px;
    }
    .title-footer-politica{
        margin-bottom: 3%;
    }
    .icon-footer{
        justify-content: center;
        margin-top: 3%;
    }
    .metododepago-footer img{
        width: 40%;
    }
    .trabaja-footer{
        justify-content: center;
        display: flex;
        margin-bottom: 3%;
    }
    .trabaja-footer a p{
        width: 100%;
        margin-left: 0px;
        padding: 6px;
    }
    .container3-footer-index img{
        width: 7%;
    }
    .footer-contacto{
        margin-top: 6%;
    }
    .container-style-contador{
        margin-top: 8%;
    }
    .contenedor_beneficios {
        margin: 2% 0% 10%;
    }
    .texto-tira-beneficios{
        font-size: 12px;
    }
}
@media screen and (max-width: 575px){
    .fondo-corporativo{
        margin-top: 4%;
    }
}
@media screen and (max-width: 567px){
    .contador-style{
        margin-top: -5%;
    }
    .contador-style-productos{
        width: 70%;
        padding: 2%;
    }
    .navbar_corporativo_flotante{
        margin-top: -7%;
    }
}
@media screen and (max-width: 554px){
    .asesor-texto{
        font-size: 12px;
    }
    .tarjetas-credito,
    .banco-pagos{
        display: block;
    }
    .container-estimado,
    .container-estimado-doble{
        margin: 3% 0% 3% 3%;
        width: 85%;
    }
    .color-texto{
        display: contents;
    }
    .tipousoformulario{
        display: block;
    }
    .escolar-imagenes{
        padding: 0% 0% 8%;
    }
    .espacio-contador {
        padding: 7% 0% 2% 0%;
    }
    .ButtonCoti{
        width: 40%;
    }
    .contorno_logo_corporativo{
        padding: 0% 1%;
    }
    .espacionombreindex {
        right: 14%;
    }
    .numeronombreindex {
        font-size: 26px;
    }
    .porcentajenombreindex {
        font-size: 15px;
    }
    .redesmovil{
        width: 70%;
    }
}

@media screen and (max-width: 547px){
    .cajadetexto_corporativo{
        font-size: 4px;
    }
}
@media screen and (max-width: 513px){
    .contador-style-productos{
        width: 80%;
    }
    .oferta-ciento{
        font-size: 12px;
    }
    .precio-online{
        padding: 0% 9%;
    }
    .oferta-producto{
        font-size: 11px;
    }
    .buscador-header[type=search]{
        width: 80%;
    }
    .buscadormovil{
        display: flex;
        justify-content: center;
    }
}
@media screen and (max-width: 503px){
    .telefono_contacto {
        width: 123%;
    }
    .cajadetexto_corporativo {
        font-size: 4px;
    }
}
@media screen and (max-width: 485px){
    .precio-general{
        font-size: 7px;
    }
    .margenlaptop, .margenpc{
        height: 85px;
    }
    .tiradeimagenes{
        height: 10%;
    }
    .laptopopc{
        margin-top: 4%;
        margin-bottom: 4%;
    }
    .formulario_corporativo {
        font-size: 12px;
    }
    .boton_de_enviar{
        font-size: 13px;
    }
    .input_contacto{
        padding-top: 4%;
    }
}
@media screen and (max-width: 476px){
    .segundo{
        padding: 0% 3%;
    }
    .oferta-ciento{
        font-size: 10px;
    }
    .oferta-producto{
        font-size: 9px;
    }
    .contorno_contacto{
        padding: 0%;
    }
}
@media screen and (max-width: 459px){
    .contador-style-productos{
        width: 100%;
    }
    .navbar_corporativo_flotante{
        margin-top: -10%;
    }
}
@media screen and (max-width: 454px){
    .telefono_contacto {
        width: 133%;
    }
    .contacto_bloque {
        margin: 3% 0%;
    }
    .porcentajenumero{
        font-size: 30px;
    }
    .porcentajepromo {
        font-size: 15px;
    }
    .nombrepromo {
        font-size: 13px;
    }
    .precioofertadetalle {
        font-size: 18px;
    }
    .precioonlinedetalle, .numeroonlinedetalle, 
    .precionormaldetalle, .numeronormaldetalle {
        font-size: 12px;
    }
    .numeronombreindex {
        font-size: 24px;
    }
    .porcentajenombreindex {
        font-size: 12px;
    }
    .descuentonombreindex {
        font-size: 9px;
    }
}
@media screen and (max-width: 446px){
    .cajadetexto_corporativo{
        font-size: 3px;
    }
}
@media screen and (max-width: 430px){
    .tarjetas-credito, 
    .banco-pagos{
        display: -webkit-box;
    }
    .espacionombreindex {
        right: 17%;
    }
    .metododepago-footer img {
        width: 80%;
    }
}
@media screen and (max-width: 420px){
    .tiraweb{
        margin-top: -2%;
    }
}
@media screen and (max-width: 413px){
    .camion-detalle{
        width: 35%;
    }
    .tienda-detalle{
        width: 29%;
    }
    .digito-detalle{
        font-size: 30px;
    }
    .promo-detalle-oferta,
    .precio-promo-detalle{
        font-size: 25px;
    }
    .oferta-ciento{
        font-size: 8px;
    }
    .oferta-producto{
        font-size: 7px;
    }
    .telefono_contacto {
        width: 143%;
    }
    .contacto_bloque {
        margin: 5% 0%;
    }
}
@media screen and (max-width: 399px){
    .contador-style-productos{
        width: 100%;
    }
    .carrito-precios,
    .carrito-precios-doble{
        width: 80%;
    }
    .ButtonCoti{
        width: 40%;
    }
    .contenedor_contacto {
        padding-top: 2%;
    }
}
@media screen and (max-width: 369px){
    .precio-tamaño {
        font-size: 15px;
    }
    .container-telefonos{
        padding-left: 8%;
    }
    .precio-normal {
        font-size: 9px;
    }
    .precio-normal-detalle {
        text-align: center;
    }
    .titulo-productos-principal{
        font-size: 12px;
    }
    .buscador-header[type=search]{
        width: 80%;
    }
    .buscadormovil{
        display: flex;
        justify-content: end;
    }
    .porcentajenumero {
        font-size: 26px;
    }
    .porcentajepromo {
        font-size: 13px;
    }
    .nombrepromo {
        font-size: 11px;
    }
    .precioofertadetalle {
        font-size: 15px;
    }
    .precioonlinedetalle, .numeroonlinedetalle, 
    .precionormaldetalle, .numeronormaldetalle {
        font-size: 10px;
    }
}
.nosotros{
    padding: 87px 100px 80px 100px;
}
.title-nosotros{
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 20px;
}
.text-content{
    margin-bottom: 1rem;
}
.btn-subir{
    display: block;
    padding: 10px 21px;
    border: 1px solid #ed571d;
    color: white;
    background: #ed571d;
    border-radius: 3px;
    cursor: pointer;
    padding: 16px 43px !important;
}
.btn-subir:hover{
    background-color: white;
    border: 1px solid #ed571d;
    color: #ed571d;
    transition: all 0.5s ease-out;
}
.postulaciones_env{
    padding: 10px 20px !important;
}
.nosotros-contacto{
    display: flex;
    justify-content: center;
}

/************************************************* PREGUNTAS FRECUENTES *********************************************/
.preguntasfree{
    text-align: center;
    padding: 3% 0% 1%;
    color: #ed571d;
    font-weight: bold;
    font-size: 30px;
}
.menupreguntas{
    margin: 0 auto;
    padding: 1% 2% 3%;
    width: 100%;
    font-family: sans-serif;
    color:#4d5974;
    font-size: 18px;
}
.title-cuestion {
    display: block;
    margin: 0 0 4px 0;
    padding : 15px 15px 15px 0;
    line-height: 1;
    cursor :pointer;
    border-bottom: 1px solid #e5e5e5;
}
.question{
    position: relative;
}
.accordion[type="checkbox"] + label:before{
    display: none;
}
.menupreguntas ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.menupreguntas li {
    height: 0;
    overflow: hidden;
    transition: all 0.5s;
    font-size: 16px;
}
#icon span {
    position: absolute;
    top: 11px;
    right: 11px;
    display: inline-block;
    width: 22px;
    height: 22px;
    border: 1px solid #4d5974;
    border-radius: 22px;
}
#icon span::before{
    display: block;
    position: absolute;
    content: '';
    top: 10px;
    left: 5px;
    width: 10px;
    height: 2px;
    background: #4d5974;
}
#icon span::after{
    display: block;
    position: absolute;
    content: '';
    top: 6px;
    left: 9px;
    width: 2px;
    height: 10px;
    background: #4d5974;
}
#type1:checked ~ #links1 li,
#type2:checked ~ #links2 li,
#type3:checked ~ #links3 li,
#type4:checked ~ #links4 li,
#type5:checked ~ #links5 li,
#type6:checked ~ #links6 li,
#type7:checked ~ #links7 li,
#type8:checked ~ #links8 li,
#type9:checked ~ #links9 li,
#type10:checked ~ #links10 li,
#type11:checked ~ #links11 li{
    height: auto;
    opacity: 1;
    padding: 1.5% 2%;
}
#type1:checked ~ .title-cuestion #icon span::after,
#type2:checked ~ .title-cuestion #icon span::after,
#type3:checked ~ .title-cuestion #icon span::after,
#type4:checked ~ .title-cuestion #icon span::after,
#type5:checked ~ .title-cuestion #icon span::after,
#type6:checked ~ .title-cuestion #icon span::after,
#type7:checked ~ .title-cuestion #icon span::after,
#type8:checked ~ .title-cuestion #icon span::after,
#type9:checked ~ .title-cuestion #icon span::after,
#type10:checked ~ .title-cuestion #icon span::after,
#type11:checked ~ .title-cuestion #icon span::after{
    width: 0;
}
#type1:checked ~ .title-cuestion,
#type2:checked ~ .title-cuestion,
#type3:checked ~ .title-cuestion,
#type4:checked ~ .title-cuestion,
#type5:checked ~ .title-cuestion,
#type6:checked ~ .title-cuestion,
#type7:checked ~ .title-cuestion,
#type8:checked ~ .title-cuestion,
#type9:checked ~ .title-cuestion,
#type10:checked ~ .title-cuestion,
#type11:checked ~ .title-cuestion{
    color: #ed571d;
    border-bottom: 1px solid #ed571d;
}
#type1:checked ~ .title-cuestion #icon span::before,
#type2:checked ~ .title-cuestion #icon span::before,
#type3:checked ~ .title-cuestion #icon span::before,
#type4:checked ~ .title-cuestion #icon span::before,
#type5:checked ~ .title-cuestion #icon span::before,
#type6:checked ~ .title-cuestion #icon span::before,
#type7:checked ~ .title-cuestion #icon span::before,
#type8:checked ~ .title-cuestion #icon span::before,
#type9:checked ~ .title-cuestion #icon span::before,
#type10:checked ~ .title-cuestion #icon span::before,
#type11:checked ~ .title-cuestion #icon span::before{
    background: #ed571d;
}
#type1:checked ~ .title-cuestion #icon span,
#type2:checked ~ .title-cuestion #icon span,
#type3:checked ~ .title-cuestion #icon span,
#type4:checked ~ .title-cuestion #icon span,
#type5:checked ~ .title-cuestion #icon span,
#type6:checked ~ .title-cuestion #icon span,
#type7:checked ~ .title-cuestion #icon span,
#type8:checked ~ .title-cuestion #icon span,
#type9:checked ~ .title-cuestion #icon span,
#type10:checked ~ .title-cuestion #icon span,
#type11:checked ~ .title-cuestion #icon span{
    border: 1px solid #ed571d;
}

/************************************************* POLITICAS DE PRIVACIDAD ***************************************************************/
.titulo-terminos{
    text-align: center;
    padding: 3% 0% 1%;
    color: #ed571d;
    font-weight: bold;
    font-size: 30px;
}
.titulo-trabajo{
    text-align: center;
    padding: 3% 0% 0%;
    color: #ed571d;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 0px;
}
.concepto-termino{
    text-align: justify;
    margin-bottom: 3%;
}
.urls{
    overflow-wrap: anywhere;
    color: #ed571d;
    padding-left: 0.5%;
    padding-right: 0.5%;
}

/************************************************* POLITICAS DE DEVOLUCIONES ***************************************************************/
.title-devoluciones{
    text-align: center;
    padding: 3% 0% 1%;
    color: #ed571d;
    font-weight: bold;
    font-size: 30px;
}
.politicas-cambios-devoluciones{
    text-align: justify;
    margin-bottom: 2%;
    margin-top: 2%;
}
.concepto-terminos{
    text-align: justify;
    margin-bottom: 1%;
}
.concepto-terminos{
    text-align: justify;
    margin-bottom: 1%;
}
.politicas-cambios {
    padding: 1% 2%;
    text-align: justify;
}
.bottom {
    margin-bottom: 5%;
}

/************************************************* TERMINOS Y CONDICIONES ***************************************************************/
.termino{
    text-align: justify;
    margin-bottom: 1rem;
}
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
  
@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.contenedor-admin{
  display: flex;
}
.espacio-admin{
  width: 100%;
}
.espacio-basico{
  width: 100%;
  padding-left: 2%;
}
.img-minilogo{
  width: 20%;
}
.mini-titulo{
  padding-left: 3%;
  width: 100%;
  margin: 0px;
}
body {
  font-size: 0.9rem;
}
.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
  transition: all 0.3s ease;
}
  
/*----------------page-wrapper----------------*/
.page-wrapper {
  height: 100vh;
}
.page-wrapper .theme {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 4px;
  margin: 2px;
}
.page-wrapper .theme.chiller-theme {
  background: #1e2229;
}
  
/*----------------toggeled sidebar----------------*/
.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}

@media screen and (min-width: 768px) {
  .page-wrapper.toggled .page-content {
    padding-left: 300px;
  }
}

/*----------------show sidebar button----------------*/
#show-sidebar {
  position: fixed;
  left: 0;
  top: 10px;
  border-radius: 0 4px 4px 0px;
  width: 35px;
  transition-delay: 0.3s;
}
.page-wrapper.toggled #show-sidebar {
  left: -40px;
}

/*----------------sidebar-wrapper----------------*/
.sidebar-wrapper {
  width: 260px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 999;
}
.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.sidebar-wrapper a {
  text-decoration: none;
}
  
/*----------------sidebar-content----------------*/
.sidebar-content {
  max-height: calc(100% - 30px);
  height: calc(100% - 30px);
  overflow-y: auto;
  position: relative;
}
.sidebar-content.desktop {
  overflow-y: hidden;
}
  
/*--------------------sidebar-brand----------------------*/
.sidebar-wrapper .sidebar-brand {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}
.sidebar-wrapper .sidebar-brand > a {
  text-transform: uppercase;
  font-weight: bold;
  flex-grow: 1;
}
.sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 20px;
}

/*--------------------sidebar-header----------------------*/
.sidebar-wrapper .sidebar-header {
  padding: 20px;
  overflow: hidden;
}
.sidebar-wrapper .sidebar-header .user-pic {
  float: left;
  width: 60px;
  padding: 2px;
  border-radius: 12px;
  margin-right: 15px;
  overflow: hidden;
}
.sidebar-wrapper .sidebar-header .user-pic img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.sidebar-wrapper .sidebar-header .user-info {
  float: left;
}
.sidebar-wrapper .sidebar-header .user-info > span {
  display: block;
}
.sidebar-wrapper .sidebar-header .user-info .user-role {
  font-size: 12px;
}
.sidebar-wrapper .sidebar-header .user-info .user-status {
  font-size: 11px;
  margin-top: 4px;
}
.sidebar-wrapper .sidebar-header .user-info .user-status i {
  font-size: 8px;
  margin-right: 4px;
  color: #5cb85c;
}
  
/*-----------------------sidebar-search------------------------*/
.sidebar-wrapper .sidebar-search > div {
  padding: 10px 20px;
}
  
/*----------------------sidebar-menu-------------------------*/
.sidebar-wrapper .sidebar-menu {
  padding-bottom: 10px;
}
.sidebar-wrapper .sidebar-menu .header-menu span {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  display: inline-block;
}
.sidebar-wrapper .sidebar-menu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 8px 30px 8px 20px;
}
.sidebar-wrapper .sidebar-menu ul li a i {
  margin-right: 10px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}
.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\F105";
  font-style: normal;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 14px;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  padding: 5px 0;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  padding-left: 25px;
  font-size: 13px;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
  content: "\F111";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  font-size: 8px;
}
.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
  float: right;
  margin-top: 0px;
}
.sidebar-wrapper .sidebar-menu .sidebar-submenu {
  display: none;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
  transform: rotate(90deg);
  right: 17px;
}
  
/*--------------------------side-footer------------------------------*/
.sidebar-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
}
.sidebar-footer > a {
  flex-grow: 1;
  text-align: center;
  height: 30px;
  line-height: 30px;
  position: relative;
}
.sidebar-footer > a .notification {
  position: absolute;
  top: 0;
}
.badge-sonar {
  display: inline-block;
  background: #980303;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  position: absolute;
  top: 0;
}
.badge-sonar:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #980303;
  opacity: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: sonar 1.5s infinite;
}
  
/*--------------------------page-content-----------------------------*/
.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  padding-left: 0px;
  padding-top: 20px;
}
.page-wrapper .page-content > div {
  padding: 20px 40px;
}
.page-wrapper .page-content {
  overflow-x: hidden;
}
  
/*------scroll bar---------------------*/
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #0e2f7f;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #0e2f7f;
}
::-webkit-scrollbar-thumb:active {
  background: #0e2f7f;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
  
/*-----------------------------chiller-theme-------------------------------------------------*/
.chiller-theme .sidebar-wrapper {
  background: #f5f5f9;
}
/* .chiller-theme .sidebar-wrapper .sidebar-header,
.chiller-theme .sidebar-wrapper .sidebar-search,
.chiller-theme .sidebar-wrapper .sidebar-menu {
  border-top: 1px solid #3a3f48;
} */
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
  border-color: transparent;
  box-shadow: none;
}
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-brand > a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.chiller-theme .sidebar-footer > a {
  color: #818896;
}
.chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover > a,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.chiller-theme .sidebar-wrapper .sidebar-brand > a:hover,
.chiller-theme .sidebar-footer > a:hover i {
  color: #b8bfce;
}
.page-wrapper.chiller-theme.toggled #close-sidebar {
  color: black;
}
.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
  color: #b8bfce;
}
.chiller-theme .sidebar-wrapper ul li:hover a i,
.chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus + span,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
  color: #ed571d;
}
.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
  color: #000;
}
.chiller-theme .sidebar-footer > a:first-child {
  border-left: none;
}
.chiller-theme .sidebar-footer > a:last-child {
  border-right: none;
}
.offcanvas-body{
  overflow-y: inherit;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.menu {
  width: 100%;
  text-transform: capitalize;
}
.menu a {
  text-decoration: none;
  color: black;
  padding: 9px 20px;
  display: block;
  font: 400 11px/12px Arial;
  transition: all 0.15s linear;
  font-size: 14px;
}
.menu > li {
  position: relative;
}
.menu a:hover {
  background: #001267;
  color: #fff !important;
  border-radius: 5px;
}
.submenu:after{
  content: ' ';
  display: block;
  border: 5px solid transparent;
  position: absolute;
  top: 2px;
  left: -15px;
  border-left-color: #ed571d !important;
  color: #ed571d !important;
  margin-top: -1%;
}
.submenu {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  text-transform: none;
  background: white;
}
.submenu li {
  max-height:0;
  transition: all 0.25s linear;
  overflow: hidden;
  transform: scale(0);
}
.submenu:after {
  content: ' ';
  display: block;
  border: 5px solid transparent;
  border-left-color: black;
  color: black;
  position: absolute;
  top: 2px;
  left: -15px;
} 
li:hover > .submenu > li {
  max-height: 44px;
  transform: scale(1);
}
.img-cont {
  height: 20rem;
  border-radius: 5px;
  overflow: hidden;
}
.img-principal{
  height: 30rem;
}
.img-galeria-product{
  height: 60px;
}
.card--skeleton .img-cont {
  background: #eee;
}
.card--skeleton::before {
  content: '';
  position: absolute;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.9),
    transparent
  );
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  animation: loading 0.6s infinite;
}
@keyframes loading {
  0% {
    transform: skewX(-10deg) translateX(-100%);
  }
  100% {
    transform: skewX(-10deg) translateX(200%);
  }
}
.productos-index .panel{
  position: absolute;
  top: 1%;
  left: -1000000000%;
  width: 18%;
  display: flex;
  align-items: center;
  flex-flow: column;
}
.contenedor_icon:hover .panel{
  left: 4%;
  opacity: 1;
}
.contenedor_icon:hover .fondo_precios{
  background: #0e2f7f42;
}
.fondo_precios{
  padding: 3% 0%;
}
.productos-index .panel .icon_product{
  margin: 12% 0;
  color: #ed571d;
}
.offcanvas-header{
  border-bottom: 2px solid #f7f7f7;
}
.offcanvas-title{
  font-size: 26px;
  margin-left: 10%;
  color: #ed571d;
}
/*tooltip Box*/
.con-tooltip {
  position: relative;
  border-radius: 9px;
  padding: 0 15%;
  margin: 6px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.tooltip {
  visibility: hidden;
  z-index: 1;
  opacity: .40;
  background: #ed571d;
  color: #fff;
  position: absolute;
  top:-140%;
  left: -25%;
  width: 250%;
  padding: 3%;
  top: 41%;
  font-size: 12px;
  border-radius: 9px;
  transform: translateY(9px);
  transition: all 0.3s ease-in-out;
}
.con-tooltip:hover .tooltip{
  visibility: visible;
  transform: translateY(-10px);
  opacity: 1;
  transition: .3s linear;
}
.right:hover{
  transform: translateX(6px);
}
.right .tooltip{ 
  left:90%;
}
.right .tooltip::after{
  top:30%;
  left:-8%;
  transform: rotate(90deg);
}

/* BUTTONS */
.button-delete{
  margin-right: 4%;
  background: #ed571d;
  border-radius: 50%;
  border: transparent;
}
.button-plus{
  margin-left: 4%;
  background: #ed571d;
  border-radius: 50%;
  border: transparent;
}

.small-img-row{
  display: block;
  justify-content: space-between;
  width: 12%;
}
.small-img-col{
  flex-basis: 24%;
  cursor: pointer;
  display: inline-flex;
}

.color-galeria{
  border-right: 3px solid #ed571d;
}
@media screen and (max-width: 1532px){
  .contenedor_icon:hover .panel {
    left: 8%;
  }
}
@media screen and (max-width: 1100px){
  .contenedor_icon:hover .panel {
    left: 11%;
  }
}
@media screen and (max-width: 769px){
  .img-principal{
    height: 17rem;
  }
  .img-galeria-product{
    height: 30px;
  }
}
:root{
    --color-background: #f6f6f9;
    --color-white: #fff;
    --color-dark: #363949;
    --color-dark-variant: #677483;
    --color-light: rgba(132, 139, 200, 0.18);
    --color-black: #000000;
}
.dark-theme-variables{
    --color-background: #181a1e;
    --color-white: #202528;
    --color-dark: #edeffd;
    --color-dark-variant: #a3bdcc;
    --color-light: rgba(0, 0, 0, 0.4);
    --color-black: #fff;
}
.containeres{
    display: grid;
    width: 99%;
    margin: 0 auto;
    grid-template-columns: 14rem auto 18rem;
}
a{
    color: black;
}
h1{
    font-weight: 800;
    font-size: 1.8rem;
}
.texto-admin{
    color: var(--color-black);
}
h2{
    font-size: 1.4rem;
}
h3{
    font-size: 0.87rem;
    margin-bottom: 0px;
}
h4{
    font-size: 0.8rem;
}
small{
    font-size: 0.75rem;
}
.profile-photo{
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    overflow: hidden;
}
.text-muted{
    color: #7d8da1;
}
b{
    color: black;
}
.primary{
    color: #7380ec;
}
.danger{
    color: #ff7782;
}
.success{
    color: #41f1b6;
}
.warning{
    color: #ffbb55;
}
aside{
    height: 100vh;
}
aside .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.4rem;
}
aside .logo{
    display: flex;
    grid-gap: 0.8rem;
    gap: 0.8rem;
}
aside .logo img{
    width: 2rem;
    height: 2rem;
}
aside .close{
    display: none;
}
/*========================= SIDEBAR ================================*/
aside .sidebar{
    display: flex;
    flex-direction: column;
    height: 86vh;
    position: relative;
    top: 3rem;
}
aside h3{
    font-weight: 500;
}
aside .sidebar a{
    display: flex;
    color: #7d8da1;
    margin-left: 2rem;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    position: relative;
    height: 3.7rem;
    text-decoration: none;
    transition: all 300ms ease;
}
aside .sidebar a .icon-dashboard{
    font-size: 1.6rem;
    transition: all 300ms ease;
}
aside .sidebar a:last-child{
    position: absolute;
    bottom: 2rem;
    width: 100%;
}
aside .sidebar a.active{
    background: var(--color-light);
    color: #7380ec;
    margin-left: 0;
}
aside .sidebar a.active:before{
    content: "";
    width: 6px;
    height: 100%;
    background: #7380ec;
}
aside .sidebar a.active i{
    color: #7380ec;
    margin-left: calc(1rem - 3px);
}
aside .sidebar a:hover{
    color: #7380ec;
}
aside .sidebar a:hover .icon-dashboard{
    margin-left: 1rem;
}
aside .sidebar .message-count{
    background: #ff7782;
    color: var(--color-white);
    padding: 2px 10px;
    font-size: 11px;
    border-radius: 0.4rem;
}
main .date{
    display: inline-block;
    background: var(--color-light);
    border-radius: 0.4rem;
    margin-top: 1rem;
    padding: 0.5rem 1.6rem;
}
main .date input[type="date"]{
    background: transparent;
    color: var(--color-black);
}
.right:hover{
    transform: inherit;
}
.right .top{
    display: flex;
    justify-content: end;
    grid-gap: 2rem;
    gap: 2rem;
}
.right .top button{
    display: none;
}
.right .theme-toggler{
    background: var(--color-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.6rem;
    width: 4.2rem;
    cursor: pointer;
    border-radius: 0.4rem;
}
.right .theme-toggler i{
    font-size: 1.2rem;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.right .theme-toggler i.active{
    background: #7380ec;
    color: white;
    border-radius: 0.4rem;
}
.right .top .profile{
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    text-align: right;
}
.ocultar{
    display: none;
}
.ver{
    display: block;
}
@media screen and (max-width: 1200px){
    .containeres{
        width: 94%;
        grid-template-columns: 7rem auto 23rem;
    }
    aside .logo h2{
        display: none;
    }
    aside .sidebar h3{
        display: none;
    }
    aside .sidebar a{
        width: 5.6rem;
    }
    aside .sidebar a:last-child{
        position: relative;
        margin-top: 1.8rem;
    }
}

@media screen and (max-width: 768px){
    .containeres{
        width: 100%;
        grid-template-columns: 1fr;
    }
    aside{
        position: fixed;
        left: -100%;
        background: var(--color-white);
        width: 18rem;
        z-index: 3;
        box-shadow: 1rem 3rem 4rem var(--color-light);
        height: 100vh;
        padding-right: 1.8rem;
        display: none;
        animation: showMenu 400ms ease forwards;
    }
    @keyframes showMenu {
        to {
            left: 0;
        }
    }
    aside .logo{
        margin-left: 1rem;
    }
    aside .logo h2{
        display: inline;
    }
    aside .sidebar h3{
        display: block;
    }
    aside .sidebar a{
        width: 100%;
        height: 3.4rem;
    }
    aside .sidebar a:last-child{
        position: absolute;
        bottom: 5rem;
    }
    aside .close{
        display: inline-block;
        cursor: pointer;
    }
    main{
        margin-top: 8rem;
        padding: 0 1rem;
    }
    /* .right{
        width: 94%;
        margin: 0 auto 4rem;
    } */
    .right .top{
        position: fixed;
        top: 0;
        left: 0;
        align-items: center;
        padding: 0 0.8rem;
        height: 4.6rem;
        background: var(--color-white);
        width: 100%;
        margin: 0;
        z-index: 2;
        box-shadow: 0 1rem 1rem var(--color-light);
    }
    .right .top .theme-toggler{
        width: 4.4rem;
        position: absolute;
        left: 66%;
    }
    .right .profile .info{
        display: none;
    }
    .right .top button{
        display: inline-block;
        background: transparent;
        cursor: pointer;
        color:  var(--color-dark);
        position: absolute;
        left: 1rem;
    }
    .right .top button i{
        font-size: 2rem;
    }
}
.titulo-admin{
    padding: 4% 0% 3% 3%;
    font-weight: bolder;
}
.quitardiv{
    display: none;
}
.agregardiv{
    display: block;
}
.categoriaelegir{
    height: 150px;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: 1px solid #9b9b9b;
    padding-bottom: 5%;
}
.btn-inverse-success:not(.btn-inverse-light) {
    color: #17c964;
}
.btn-inverse-success {
    background-color: rgba(23, 201, 100, 0.2);
    background-image: none;
    border-color: rgba(23, 201, 100, 0);
}
.btn-inverse-success:hover {
    color: #ffffff;
    background-color: #17c964;
    border-color: #17c964;
}
.btn:focus,
.btn:active{
    outline: none;
    box-shadow: none;
}
.button-categoria{
    justify-content: end;
    display: flex;
    padding: 0%;
}
.tabla-admin{
    padding: 3% 0% 0% 5%;
}
.form__group {
    position: relative;
    width: 100%;
    padding: 7% 10% 2% 10%;
}
.form__img{
    position: relative;
    width: 30%;
}
.form__field {
    font-family: inherit;
    width: 100%;
    border: none;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 17px;
    color: black;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}
.form__field::placeholder {
    color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
    font-size: 17px;
    cursor: text;
    top: 20px;
}
.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 17px;
    color: #9b9b9b;
    pointer-events: none;
}
.form__field:focus {
    padding-left: 6px;
    font-weight: 400;
    border-image: linear-gradient(to right, #ed571d, #ed571d);
    border-image-slice: 1;
}
.form__field:focus ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 17px;
    color: #ed571d;
    font-weight: 700;
}
.form__field:required, .form__field:invalid {
    box-shadow: none;
}
.edit-admin{
    cursor: pointer;
}
.form-select:focus,
.form-select:active{
    outline: none;
    box-shadow: none;
}

.image-input {
    text-align: center;
}
.image-input input {
    display: none;
}
.image-input label {
    display: block;
    color: #FFF;
    background: #ed571d;
    padding: 0.3rem 0.6rem;
    font-size: 115%;
    cursor: pointer;
}
.image-input label i {
    font-size: 125%;
    margin-right: 0.3rem;
}
.image-input label:hover i {
    animation: shake 0.35s;
}
.image-input img {
    max-width: 200px;
    display: none;
}
.image-input span {
    display: none;
    text-align: center;
}
.change-image{
    cursor: pointer;
    display: block;
    background-color: red;
    padding: 1%;
    color: white;
    margin-top: 2%;
}
.change-imagen{
    cursor: pointer;
    display: block;
    background-color: red;
    padding: 1%;
    color: white;
    margin-top: 2%;
}
.change-imagens{
    cursor: pointer;
    display: block;
    background-color: red;
    padding: 1%;
    color: white;
    margin-top: 2%;
}
.change-image-two{
    cursor: pointer;
    display: block;
    background-color: red;
    padding: 1%;
    color: white;
    margin-top: 2%;
}
.change-image-edit-galeria{
    cursor: pointer;
    display: block;
    background-color: red;
    padding: 1%;
    color: white;
    margin-top: 2%;
}
.change-image-prigaleri{
    cursor: pointer;
    display: block;
    background-color: red;
    padding: 1%;
    color: white;
    margin-top: 2%;
}
.change-imagen-twosec{
    cursor: pointer;
    display: block;
    background-color: red;
    padding: 1%;
    color: white;
    margin-top: 2%;
}
.change-image-primg{
    cursor: pointer;
    display: block;
    background-color: red;
    padding: 1%;
    color: white;
    margin-top: 2%;
}
.image-preview-galeria-edit{
    margin: auto;
}
.image-preview-twoedit{
    margin: auto;
}
.image-previewedit{
    margin: auto;
}
.image-preview-primg{
    margin: auto;
}
.image-previewtwo{
    margin: auto;
}
.image-preview-galeria-pri{
    margin: auto;
}
@keyframes shake {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(10deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
.nav-tabs .nav-link{
    color: black;
    /* margin-left: 1%; */
}
.nav-tabs .nav-link.active{
    background-color: #ed571d;
    border-color: #ed571d;
}
/*Checkboxes styles*/
input[type="checkbox"] { 
    display: none; 
}
input[type="checkbox"] + label {
    display: block;
    position: relative;
    padding-left: 27px;
    font-size: 17px;
    color: #9b9b9b;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
input[type="checkbox"] + label:last-child { 
    margin-bottom: 0; 
}
input[type="checkbox"] + label:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid #ed571d;
    position: absolute;
    left: 0;
    top: 0;
    transition: all .12s, border-color .08s;
}
input[type="checkbox"]:checked + label:before {
    width: 10px;
    top: -5px;
    left: 5px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(45deg);
}
